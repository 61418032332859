import Box from '@components/Box';
import Flex from '@components/Flex';
import { SelectMenuProps, SelectOption, SelectProps, SelectSize } from '@components/Select/types';
import Typography from '@components/Typography';
import { TypographyVariant } from '@components/Typography/types';
import { ChevronDown16, ChevronUp16 } from '@icons/index';
import { Property as CSSProperty } from 'csstype';
import {
	ControlProps,
	DropdownIndicatorProps,
	MenuListProps,
	OptionProps,
	default as ReactSelect,
	components as ReactSelectComponents,
	SingleValue,
	StylesConfig,
} from 'react-select';
import colors from 'src/style/colors';
import ListItem from '../ListItem';
import { ListItemSize } from '../ListItem/types';
import './select.scss';

const { Control: ReactSelectControl, MenuList: ReactSelectMenuList } = ReactSelectComponents;

function setLabelIfEmpty<T>(option: SelectOption<T>) {
	return {
		label: option.label ?? option.value,
		value: option.value,
		icon: option.icon,
		isDisabled: option.isDisabled,
		tooltip: option.tooltip ?? null,
	};
}

export function Select<T = string>({
	size = 'large',
	width,
	placeholder = 'Select',
	isDisabled = false,
	isError = false,
	isGhost = false,
	options,
	selectedOption: selectedOption,
	menuStyles = {},
	menuPlacement = 'bottom',
	borderRadius = '4px',
	testId = 'select',
	paddingLeft = '0',
	stickyFooter,
	maxMenuHeight,
	menuIsOpen,
	onMenuOpen,
	onMenuClose,
	onChange,
	isStoppingClickPropagation = true,
	backgroundColor,
	isSearchable,
	iconsColor = 'black',
	color,
}: SelectProps<T>) {
	const DropdownIndicator = ({ selectProps: { menuIsOpen } }: DropdownIndicatorProps) => {
		return arrowsBySize[size][menuIsOpen.toString()];
	};
	const Control = ({ children, ...props }: ControlProps<SelectOption>) => {
		const value = props.selectProps.value as SingleValue<SelectOption>;
		const isIconDisplayed = !!value?.icon;
		return (
			<ReactSelectControl {...props}>
				{isIconDisplayed && (
					<Box paddingRight="8px" color={iconsColor}>
						{value.icon}
					</Box>
				)}
				{children}
			</ReactSelectControl>
		);
	};

	const normalizedOptionsToShow = options
		.map(setLabelIfEmpty)
		.map((e) => ({ ...e, testId: `${testId}-option-${e.value}` }));

	const normalizedSelectedOption = selectedOption && setLabelIfEmpty(selectedOption);
	const isInline = size == 'inline';

	const OptionElement = (props: OptionProps<SelectOption>) => {
		return (
			// TODO: maybe not use the ListItem from the design system...
			<Flex
				flexWrap="nowrap"
				width="100%"
				ref={props.innerRef}
				backgroundColor={props.data.value == selectedOption?.value ? 'blue.200' : 'white'}
			>
				<ListItem
					tooltip={props.data.tooltip}
					state={props.isDisabled ? 'disabled' : 'enabled'}
					testId={props.data.testId}
					size={listItemBySize[size]}
					label={props.data.label ?? props.data.value}
					prefixIcon={props.data.icon && <Box color={iconsColor}>{props.data.icon}</Box>}
					onClick={() => props.selectOption(props.data)}
				/>
			</Flex>
		);
	};

	const MenuList = (props: MenuListProps<SelectOption>) => {
		return (
			<div>
				<ReactSelectMenuList {...props}>{props.children}</ReactSelectMenuList>
				{stickyFooter}
			</div>
		);
	};

	const hasWidth = !isInline && width;
	const mainWidth = !hasWidth ? 'auto' : width;

	return (
		//TODO: Width breaks inline that should be next to the chevron
		<Typography variant={typographyBySize[size]} width={mainWidth} paddingLeft={paddingLeft} textAlign="start">
			<span onClick={isStoppingClickPropagation ? (e) => e.stopPropagation() : () => void 0} data-testid={testId}>
				<ReactSelect<any>
					onChange={onChange}
					styles={getStyle<T>(size, isError, isGhost, menuStyles, borderRadius, width, backgroundColor, color)}
					options={normalizedOptionsToShow}
					isSearchable={isSearchable ?? false}
					components={{
						DropdownIndicator,
						Option: OptionElement,
						Control: Control,
						MenuList,
					}}
					value={normalizedSelectedOption}
					isDisabled={isDisabled}
					placeholder={placeholder}
					classNamePrefix={'select'}
					maxMenuHeight={maxMenuHeight}
					menuPlacement={menuPlacement}
					menuPortalTarget={document.querySelector('body')}
					onMenuOpen={onMenuOpen}
					onMenuClose={onMenuClose}
					menuIsOpen={menuIsOpen}
				/>
			</span>
		</Typography>
	);
}

function getStyle<T>(
	size: SelectSize,
	isError: boolean,
	isGhost: boolean,
	menuStyles: SelectMenuProps,
	borderRadius: CSSProperty.BorderRadius,
	width?: CSSProperty.Width,
	backgroundColor?: CSSProperty.BackgroundColor,
	color?: CSSProperty.Color
): StylesConfig<SelectProps<T>> {
	const isInline = size == 'inline';

	const defaultWidth = isInline || !width ? 'min-content' : width;
	const defaultMargin = '8px 0 0 0px';
	return {
		menuPortal: (p) => ({ ...p, zIndex: 1500 }),
		indicatorSeparator: () => ({
			display: 'hidden',
		}),
		// eslint-disable-next-line @typescript-eslint/no-unused-vars
		input: ({ margin, ...providedCss }) => ({ ...providedCss, padding: 0 }),
		menu: (providedCss) => ({
			...providedCss,
			width: menuStyles?.width ?? defaultWidth,
			minWidth: menuStyles?.minWidth,
			maxWidth: menuStyles?.maxWidth,
			margin: menuStyles?.margin ?? defaultMargin,
			borderRadius: '8px',
			whiteSpace: 'nowrap',
			boxShadow: '0px 2px 4px -4px rgba(26, 29, 33, 0.06), 0px 4px 8px -2px rgba(26, 29, 33, 0.08)',
			paddingTop: '8px',
			paddingBottom: '8px',
			paddingLeft: '0px',
			paddingRight: '0px',
			borderWidth: '1px',
			borderColor: colors.gray['300'],
			borderStyle: 'solid',
		}),
		menuList: (providedCss) => ({
			...providedCss,
			paddingTop: '0px',
			paddingBottom: '0px',
		}),
		// eslint-disable-next-line @typescript-eslint/no-unused-vars
		control: ({ width, margin, ...providedCss }, state) => {
			function getBorderColors() {
				let borderColor: CSSProperty.Color = colors.gray['400'];
				let hoverBorderColor: CSSProperty.Color = colors.gray['500'];

				if (state.menuIsOpen) hoverBorderColor = borderColor = colors.gray['800'];
				if (isError) hoverBorderColor = borderColor = colors.red['900'];
				return { borderColor, hoverBorderColor };
			}

			const shouldDisableBorder = isInline || isGhost;
			const borderWidth = shouldDisableBorder ? '0px' : '1px';
			backgroundColor ??= state.isDisabled && !isInline ? colors.gray['100'] : colors.white;
			const { borderColor, hoverBorderColor } = getBorderColors();

			return {
				...providedCss,
				borderWidth,
				borderColor,
				backgroundColor: isGhost ? 'transparent' : backgroundColor,
				borderRadius: borderRadius,
				padding: paddingsBySize[size],
				boxShadow: 'none',
				minHeight: '0px',
				color: state.isDisabled ? colors.gray[500] : color ?? colors.gray[800],
				':hover': {
					boxShadow: 'none',
					borderColor: hoverBorderColor,
					color: isInline ? colors.gray[600] : undefined,
				},
				':active': {
					boxShadow: 'none',
				},
			};
		},
		valueContainer: (providedCss) => ({
			...providedCss,
			padding: isInline ? '0px 4px 0px 0px' : '0px 8px 0px 0px',
		}),
		placeholder: (providedCss) => ({ ...providedCss, color: colors.gray['600'] }),
		singleValue: (providedCss) => {
			return {
				...providedCss,
				color: 'inherit',
				margin: 0,
			};
		},
	};
}

const arrowsBySize: { [key in SelectSize]: any } = {
	button: { true: <></>, false: <></> },
	inline: { true: <ChevronUp16 />, false: <ChevronDown16 /> },
	tiny: { true: <ChevronUp16 />, false: <ChevronDown16 /> },
	small: { true: <ChevronUp16 />, false: <ChevronDown16 /> },
	medium: { true: <ChevronUp16 />, false: <ChevronDown16 /> },
	large: { true: <ChevronUp16 />, false: <ChevronDown16 /> },
};

const paddingsBySize: { [key in SelectSize]: string } = {
	button: '0',
	inline: '0',
	tiny: '5px 12px',
	small: '7px 9px 7px 11px',
	medium: '7px 12px',
	large: '12px 16px',
};

const typographyBySize: { [key in SelectSize]: TypographyVariant } = {
	large: 'DesktopH7Regular',
	medium: 'DesktopH7Regular',
	small: 'DesktopH8Regular',
	tiny: 'DesktopH8Regular',
	inline: 'DesktopH8Regular',
	button: 'DesktopH10Medium',
};

const listItemBySize: { [key in SelectSize]: ListItemSize } = {
	large: 'lg',
	medium: 'lg',
	small: 'sm',
	tiny: 'sm',
	inline: 'sm',
	button: 'sm',
};
