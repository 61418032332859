import Button from '@components/Button';
import Tooltip from '@components/Tooltip';
import { Camera16 } from '../../../../common/components/Icons';
import { TAKE_CHART_SNAPSHOT } from '@components/MetricView/ExportPng/ExportPngButton';

export function CameraButton({ isDisabled }: { isDisabled: boolean }) {
	return (
		<>
			<Tooltip label="Chart Snapshot" size="md" variant="fluid" background="black" hasArrow={true} marginTop="8px">
				<Button
					isDisabled={isDisabled}
					isIconOnly={true}
					variant="outline"
					colorScheme={'black'}
					onClick={() => window?.postMessage(TAKE_CHART_SNAPSHOT)}
					size={'inline'}
					data-intercom-area={'metric'}
					data-intercom-type={'button'}
					data-intercom-target={'Lineage'}
				>
					<Camera16 />
				</Button>
			</Tooltip>
		</>
	);
}
