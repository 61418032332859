import eq from 'lodash/fp/eq';
import some from 'lodash/fp/some';
import { PeriodUnit } from '@sightfull/period-ranges';

const PeriodScale = [
	[''],
	['day'],
	['week', 'fweek'],
	['month'],
	['cquarter', 'quarter', 'fquarter'],
	['fyear'],
	['custom'],
	[null],
];

export default function calcEffectiveCompareToUnit(selectedPeriodUnit?: PeriodUnit, compareToPeriodUnit?: PeriodUnit) {
	const selectedPeriodUnitWeight = PeriodScale.findIndex(some(eq(selectedPeriodUnit)));
	const compareToPeriodUnitWeight = PeriodScale.findIndex(some(eq(compareToPeriodUnit)));
	return compareToPeriodUnit && selectedPeriodUnitWeight > compareToPeriodUnitWeight
		? selectedPeriodUnit
		: compareToPeriodUnit;
}
