import { ChartSeries } from '@components/Chart/types';
import { sortBySortOrder } from '@components/Chart/useSeries';
import difference from 'lodash/difference';
import { useEffect, useState } from 'react';
import { useMetricDerivedState } from 'src/pages/MetricPage/hooks/useMetricDerivedState';
import { useMetricPageSearchParams } from 'src/pages/MetricPage/hooks/useMetricPageSearchParams';
import { MultiSelector } from 'src/pages/MetricPage/utils/state.types';
import { LegendPanelData, MetricComponent } from './types';
import uniq from 'lodash/fp/uniq';

type Actions = {
	setIsAllComponentsSelected: () => void;
	clearAllComponentsSelected: () => void;
	toggleLegend: (legend: string) => void;
};

export const componentsTitle = 'Components';

export function useDisplayedLegendState(): [LegendPanelData, Actions] {
	const derivedState = useMetricDerivedState();
	const { displayedLegendItems, metricDisplayName, breakdowns, chartOptions, availableTargets, metricInfo, flavor } =
		derivedState;
	const { searchParams, setPartialSearchParams, setSearchParams } = useMetricPageSearchParams();

	const hiddenLegends = difference(displayedLegendItems.optionalValues, displayedLegendItems.selectedValues);
	const breakdown = breakdowns.values?.[0];

	const [legendsState, setLegendsState] = useState<LegendPanelData>({
		components: [],
		title: componentsTitle,
		mainSeries: [],
		isBrokenDown: false,
	});

	const setLegendsTitle = () => {
		setLegendsState((s) => ({
			...s,
			title: breakdown?.label ?? componentsTitle,
			isBrokenDown: breakdown?.label != undefined,
		}));
	};
	useEffect(setLegendsTitle, [breakdown]);

	useEffect(() => {
		const mainMetricName = metricDisplayName;
		const isMainSeriesAvailable = displayedLegendItems.optionalValues.includes(mainMetricName);
		const allMainSeriesNames = isMainSeriesAvailable ? [mainMetricName, ...availableTargets] : availableTargets;
		const mainSeries = getMainSeries(displayedLegendItems, chartOptions?.series, allMainSeriesNames);
		const components = uniq(displayedLegendItems.optionalValues)
			.filter((v) => !allMainSeriesNames.includes(v))
			.sort((a, b) =>
				sortBySortOrder(
					chartOptions?.series.find((s) => s.name == a) ?? {},
					chartOptions?.series.find((s) => s.name == b) ?? {}
				)
			)
			.map<MetricComponent>((optionalDisplayLegendValue) => {
				const series = chartOptions?.series.find((s) => s.name == optionalDisplayLegendValue);
				const componentRawName = encodeURIComponent(series?.custom?.rawName || '');
				const isCleanNameAvailable = !!series?.custom?.cleanName;
				const componentCleanName = isCleanNameAvailable
					? encodeURIComponent(series?.custom?.cleanName || '')
					: componentRawName;

				return {
					id: series?.id,
					label: optionalDisplayLegendValue,
					appliedParameters: series?.custom?.appliedParameters,
					value: componentRawName,
					metricName: componentCleanName,
					parametersOverride: series?.custom?.parametersOverride,
					color: series?.color,
					isChecked: displayedLegendItems.selectedValues.includes(optionalDisplayLegendValue),
				};
			});

		setLegendsState((currentState) => ({
			...currentState,
			components,
			mainSeries,
		}));
	}, [
		availableTargets,
		breakdowns,
		chartOptions?.series,
		derivedState.periodRange,
		displayedLegendItems,
		flavor?.selectedValue,
		metricDisplayName,
		metricInfo,
	]);

	function setIsAllComponentsSelected() {
		const shouldTurnAllOn = legendsState.components.some((component) => !component.isChecked);
		const componentNames = legendsState.components.map((s) => s.label ?? '');

		if (shouldTurnAllOn) {
			const hiddenLegendsWithoutComponents = difference(hiddenLegends, componentNames);

			setSearchParams({
				...searchParams,
				hiddenLegendNames: hiddenLegendsWithoutComponents,
			});
		} else {
			clearAllComponentsSelected();
		}
	}

	function clearAllComponentsSelected() {
		const componentNames = legendsState.components.map((s) => s.label ?? '');
		setSearchParams({
			...searchParams,
			hiddenLegendNames: [...hiddenLegends, ...componentNames],
		});
	}

	function toggleLegend(legendKey: string) {
		const isLegendAlreadyOn = displayedLegendItems.selectedValues.includes(legendKey);
		const newDisplayedLegends = isLegendAlreadyOn
			? displayedLegendItems.selectedValues.filter((v) => v != legendKey)
			: [...displayedLegendItems.selectedValues, legendKey];
		const newHiddenLegends = difference(displayedLegendItems.optionalValues, newDisplayedLegends);
		setPartialSearchParams({ hiddenLegendNames: newHiddenLegends });
	}

	return [
		legendsState,
		{
			clearAllComponentsSelected,
			setIsAllComponentsSelected,
			toggleLegend,
		},
	];
}

function getMainSeries(
	displayedLegendItems: MultiSelector<string>,
	chartSeries: ChartSeries[] | undefined,
	allMainSeriesNames: string[]
): MetricComponent[] {
	const findSeriesColor = (seriesName: string) => {
		return chartSeries?.find((s) => s.name == seriesName)?.color;
	};

	const findSeriesAppliedParameters = (seriesName: string) => {
		return chartSeries?.find((s) => s.name == seriesName)?.custom?.appliedParameters;
	};

	return allMainSeriesNames.map((seriesName) => ({
		id: chartSeries?.find((s) => s.name == seriesName)?.id,
		value: seriesName,
		metricName: seriesName,
		color: findSeriesColor(seriesName),
		appliedParameters: findSeriesAppliedParameters(seriesName),
		isChecked: displayedLegendItems.selectedValues.includes(seriesName),
	}));
}
