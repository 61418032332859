import { useMetricDerivedState } from '@pages/MetricPage/hooks/useMetricDerivedState';
import { ExtremesObject } from 'highcharts';
import { useCallback, useEffect, useState } from 'react';
import { ColumnSeries, LineSeries, PieSeries, WaterfallSeries, YAxis } from 'react-jsx-highcharts';
import { YAxisConfig } from 'src/common/utils/MetricSearchParams';
import {
	SeriesSinglePeriodPlaceholderName,
	STATS_SERIES_NAMES,
} from 'src/lib/metricRules/statisticOperations/constants';
import { DisplayLegendItems } from 'src/pages/MetricPage/utils/state.types';
import { isCoreMetricUnitType } from 'src/types/metric';
import { ChartOptions, ChartSeries, ChartType, SeriesDataPointObject } from './types';
import { chartConfig } from './useMetricChartGeneralConfig';

type setExtremesFn = (extremes: ExtremesObject) => void;

const chartTypeSortValues: Record<ChartType, number> = {
	column: 0,
	line: 1,
	pie: 0,
	stackedColumn: 0,
	waterfall: 0,
	number: 0,
	attainment: 0,
};

type ChartTypesToSeries = Partial<Record<ChartType, ChartSeries>>;

export const sortBySortOrder = (seriesA: Partial<ChartSeries>, seriesB: Partial<ChartSeries>) => {
	if (!seriesA.custom || !seriesB.custom) {
		return 0;
	}
	switch (seriesA.custom.seriesType) {
		case 'component':
			if (seriesB.custom.seriesType == 'component')
				return (seriesA.custom.seriesOrder ?? 0) - (seriesB.custom.seriesOrder ?? 0);
			return -1;
		case 'main':
			if (seriesB.custom.seriesType == 'main' && !seriesB.custom.isTarget) {
				return 0;
			}
			return 1;
		case 'statistic':
			return 0;
		default:
			return 0;
	}
};

const reduceSeriesByChartType = (allSeries: ChartSeries[]): ChartSeries[] => {
	const mainSeries = allSeries.find((s) => s.custom.seriesType == 'main' && !s.custom.isTarget);
	const visibleComponentsLength = allSeries.filter((s) => s.visible && s.custom.seriesType == 'component').length;

	const seriesByType = allSeries.reduce<ChartTypesToSeries>((seriesAccumulator, currentSeries) => {
		if (!currentSeries.visible) {
			return seriesAccumulator;
		}

		const isMain = currentSeries.custom.seriesType == 'main';
		const isSeriesWaterfallSum = visibleComponentsLength > 0 && isMain && !mainSeries?.custom.isTarget;

		const getSpecialSeriesName = () => {
			return isMain && mainSeries?.name ? mainSeries.name : currentSeries.name;
		};

		const name = currentSeries.custom.isTarget ? currentSeries.name : SeriesSinglePeriodPlaceholderName;
		const seriesInfoLifted: ChartSeries = {
			name,
			chartType: currentSeries.chartType,
			custom: mainSeries?.custom ?? currentSeries.custom,
			data: [
				{
					...currentSeries.data[0],
					name: getSpecialSeriesName(),
					color: currentSeries.color,
					isSum: isSeriesWaterfallSum,
				},
			],
		};
		const chartType = currentSeries.chartType;
		const previousChartType = seriesAccumulator[chartType];
		if (previousChartType) {
			const appendedData = [...previousChartType.data, ...seriesInfoLifted.data];

			return {
				...seriesAccumulator,
				[chartType]: { ...previousChartType, data: appendedData },
			};
		}
		return { ...seriesAccumulator, [chartType]: seriesInfoLifted };
	}, {} as ChartTypesToSeries);

	return Object.values(seriesByType);
};

const useSeries = (
	options: ChartOptions,
	handleSetExtremes: setExtremesFn,
	maxYValue: number | null,
	displayedLegendsNames: DisplayLegendItems,
	isSinglePeriodViewOn: boolean
) => {
	const { yAxisConfig } = useMetricDerivedState();
	const [seriesElements, setSeriesElements] = useState<JSX.Element[] | null>(null);
	const isTargetAttainmentMetric = options.series.some((s) => s.chartType == 'attainment');
	const shouldLowerChartHeightDueToBubbles = (isTargetAttainmentMetric || options.bubbles?.length) && maxYValue;
	const heightReduceFactorByStatisticsBubbles = 2;
	const heightReduceFactoryByTargetAttainmentDots = 1.3;
	const bubblesMaxChartYValue = shouldLowerChartHeightDueToBubbles
		? isTargetAttainmentMetric
			? maxYValue * heightReduceFactoryByTargetAttainmentDots
			: maxYValue * heightReduceFactorByStatisticsBubbles
		: undefined;

	const setFilterByVisibility = useCallback(
		(series: SeriesDataPointObject) => {
			return displayedLegendsNames.selectedValues.includes(series.name);
		},
		[displayedLegendsNames.selectedValues]
	);

	const setVisibility = useCallback(
		(series: ChartSeries) => ({
			...series,
			visible: [...displayedLegendsNames.selectedValues, ...STATS_SERIES_NAMES].includes(series.name) && series.visible,
		}),
		[displayedLegendsNames.selectedValues]
	);

	function getSortedSeries(seriesWithVisibility: ChartSeries[]) {
		return seriesWithVisibility
			.sort((seriesA, seriesB) => chartTypeSortValues[seriesA.chartType] - chartTypeSortValues[seriesB.chartType])
			.sort(sortBySortOrder);
	}

	const handleSeriesChange = () => {
		const seriesWithVisibility: ChartSeries[] = options.series.map(setVisibility);
		const seriesWithSort = getSortedSeries(seriesWithVisibility);
		const fixedSeriesIfNeeded = isSinglePeriodViewOn ? reduceSeriesByChartType(seriesWithSort) : seriesWithSort;
		const series = normalizeSeries(fixedSeriesIfNeeded);

		const yAxisKeys = series ? Object.keys(series) : [];
		const seriesElementsByAxis = buildSeriesElementsByYAxisKey(
			series,
			yAxisKeys,
			handleSetExtremes,
			bubblesMaxChartYValue,
			yAxisConfig
		);

		if (seriesElementsByAxis) {
			setSeriesElements(seriesElementsByAxis);
		}
	};

	useEffect(handleSeriesChange, [
		options.series,
		bubblesMaxChartYValue,
		displayedLegendsNames.selectedValues,
		handleSetExtremes,
		setFilterByVisibility,
		setVisibility,
		isSinglePeriodViewOn,
		yAxisConfig,
	]);
	return seriesElements;
};

const getSeriesComponentByChartType = (type: ChartType) => {
	switch (type) {
		case 'line':
		case 'attainment':
			return LineSeries;

		case 'waterfall':
			return WaterfallSeries;

		case 'pie':
			return PieSeries;

		case 'column':
		case 'stackedColumn':
		default:
			return ColumnSeries;
	}
};

const getChartConfigByType = (type: ChartType): any => {
	return chartConfig[type] || {};
};

export default useSeries;

const buildSeriesElementsByYAxisKey = (
	series: Record<string, ChartSeries[]> | null,
	yAxisKeys: string[],
	handleSetExtremes: setExtremesFn,
	bubblesMaxChartYValue?: number,
	yAxisConfig?: YAxisConfig
) => {
	if (!series) return null;

	return yAxisKeys.map((key: string) => {
		const axisSeries = series[key];
		const newSeries = buildSeriesComponents(axisSeries);

		const { min, max } = normalizeSeriesMinMaxByUnit(axisSeries, key, bubblesMaxChartYValue, yAxisConfig);

		const seriesWithYAxis = (
			<YAxis key={key} onAfterSetExtremes={handleSetExtremes} visible={false} min={min} max={max}>
				{newSeries}
			</YAxis>
		);

		return seriesWithYAxis;
	});
};

const buildSeriesComponents = (axisSeries: ChartSeries[]) => {
	//const allTypesStr = axisSeries.map((s) => s.chartType).join();
	return axisSeries.map((seriesItem: ChartSeries, index) => {
		const SeriesComponent = getSeriesComponentByChartType(seriesItem.chartType);
		const configuration = getChartConfigByType(seriesItem.chartType);
		const adjustedSeries = pinSeriesHeightIfNeeded(seriesItem);
		//const currentSortOrder = axisSeries.map((s) => s.name).join(',');
		// The compound key is a hack to mitigate some bugs either in hc or the react binding
		//const compKey = `${adjustedSeries.name}-${adjustedSeries.custom.seriesType}-${allTypesStr}-${adjustedSeries.custom.seriesOrder}-${i}${currentSortOrder}`;
		return (
			<SeriesComponent
				id={seriesItem.id}
				key={index}
				{...configuration}
				{...adjustedSeries}
				color={adjustedSeries.color}
			/>
		);
	});
};

const pinSeriesHeightIfNeeded = (series: ChartSeries) => {
	return series.chartType == 'attainment'
		? {
				...series,
				data: series.data.map((point) => {
					return { ...point, y: 0.9 };
				}),
		  }
		: series;
};

const normalizeSeries = (series: ChartSeries[]) => {
	if (!series) return null;

	const seriesByYAxis: Record<string, ChartSeries[]> = {};

	for (let i = 0; i < series.length; i++) {
		const seriesItem = series[i];
		if (seriesItem.yAxis) {
			seriesByYAxis[seriesItem.yAxis] = [...(seriesByYAxis[seriesItem.yAxis] || []), seriesItem];
		} else {
			seriesByYAxis['default'] = [...(seriesByYAxis['default'] || []), seriesItem];
		}
	}

	return seriesByYAxis;
};

export const normalizeSeriesMinMaxByUnit = (
	series: ChartSeries[],
	unit?: string,
	bubblesLimit?: number,
	yAxisConfig?: YAxisConfig
): { min?: number; max?: number } => {
	const seriesUnit = series[0].custom.unit;
	const minFromUrl = isCoreMetricUnitType(seriesUnit) ? yAxisConfig?.[seriesUnit]?.min : undefined;
	const maxFromUrl = isCoreMetricUnitType(seriesUnit) ? yAxisConfig?.[seriesUnit]?.max : undefined;
	if (minFromUrl && maxFromUrl) {
		return { min: minFromUrl, max: maxFromUrl };
	}

	const isTargetAttainment = series.some((s) => s.chartType == 'attainment');
	if (isTargetAttainment) return { min: minFromUrl ?? 0, max: maxFromUrl ?? 1 };

	const isPercentage = unit == 'percentage';
	if (!isPercentage) return { min: minFromUrl, max: maxFromUrl ?? bubblesLimit };

	const yNumbers = series
		.flatMap((specificSeries) => specificSeries.data.map((point) => point.y))
		.filter((y) => y != undefined);

	const defaultMinimumPercentageY = 0;
	const defaultMaximumPercentageY = 1;
	const maxYPercentageValue = Math.max(...yNumbers, defaultMaximumPercentageY);
	const minYPercentageValue = yNumbers.length
		? Math.min(...yNumbers, defaultMinimumPercentageY)
		: defaultMinimumPercentageY;
	return { min: minFromUrl ?? minYPercentageValue, max: maxFromUrl ?? maxYPercentageValue };
};
