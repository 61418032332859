import { PeriodRange } from '@sightfull/period-ranges';
import { ChartSeries, ChartType, ID_PREFIX_SERIES_TIME_SPAN_SECONDARY } from 'src/common/components/Chart/types';
import { Breakdowns } from 'src/pages/MetricPage/utils/state.types';

const SINGLE_PERIOD_CHART_TYPE: readonly ChartType[] = ['waterfall', 'column'] as const;

export const getSinglePeriodSeries = (series: ChartSeries[]) =>
	series?.find((s) => SINGLE_PERIOD_CHART_TYPE.includes(s.chartType));

export const isSinglePeriodWaterfall = (series?: ChartSeries[]) => !!series?.find((s) => s.chartType === 'waterfall');
export const isSinglePeriodPie = (series?: ChartSeries[]) => !!series?.find((s) => s.chartType === 'pie');

export const isSinglePeriodView = (periodRange: PeriodRange, breakdowns: Breakdowns, series?: ChartSeries[]) => {
	const isSinglePeriodWithGroupBy =
		periodRange.isSinglePeriod &&
		!!breakdowns.values.length &&
		!series?.some(({ id }) => id?.startsWith(ID_PREFIX_SERIES_TIME_SPAN_SECONDARY));
	const isWaterfallChart = isSinglePeriodWaterfall(series);
	const isPieChart = isSinglePeriodPie(series);

	return isSinglePeriodWithGroupBy || isWaterfallChart || isPieChart;
};
