import {
	ChartOptions,
	ChartSeries,
	componentSeriesCustomField,
	ID_PREFIX_SERIES_TIME_SPAN_PRIMARY,
	ID_PREFIX_SERIES_TIME_SPAN_SECONDARY,
	mainSeriesCustomField,
	statisticSeriesCustomField,
} from 'src/common/components/Chart/types';
import { MetricDerivedState } from 'src/pages/MetricPage/utils/state.types';
import colors from 'src/style/colors';
import { StatisticalOperation } from 'src/types/metric';
import {
	ComponentsSeriesColors,
	MainSeriesColors,
	ORDERED_STATISTIC_OPERATIONS,
} from '../statisticOperations/constants';
import { ChartColoring } from '../utils';
import matches from 'lodash/fp/matches';

const PATTERN_DIAGONAL_LINES = 'M 5 0 L 0 5 L 0 3 L 3 0 Z M 3 5 L 5 5 L 5 3 Z';

// TBD: We may want to move all "Highcharts" specific logic to centralized "Chart" component(s)
const patternColor = (color: string, pattern = PATTERN_DIAGONAL_LINES) => {
	return {
		pattern: {
			path: {
				d: pattern,
				stroke: 'transparent',
				fill: color,
			},
			color,
			x: 0,
			y: 0,
			width: 5,
			height: 5,
			opacity: 1,
		},
	};
};

export function calcColoredSeries(
	{ chartOptions }: Required<Pick<MetricDerivedState, 'chartOptions'>>,
	tenantGraphColor: string[]
): Pick<MetricDerivedState, 'chartOptions'> {
	const isSpecificGraphColorsExist = tenantGraphColor.length > 2;
	const tenantSpecificMainSeriesColors = isSpecificGraphColorsExist
		? [tenantGraphColor[0], ...MainSeriesColors.slice(1)]
		: MainSeriesColors;

	const tenantSpecificComponentSeriesColors = isSpecificGraphColorsExist
		? tenantGraphColor.slice(1)
		: ComponentsSeriesColors;

	const chartColoring: ChartColoring = {
		mainSeriesColors: tenantSpecificMainSeriesColors,
		componentSeriesColors: tenantSpecificComponentSeriesColors,
	};

	const isWaterfall = !!chartOptions.series.find((s) => s.chartType == 'waterfall');
	if (isWaterfall) {
		return calcColoredSeriesForWaterfall({ chartOptions, ...chartColoring });
	}

	return calcDefaultColoredSeries({ chartOptions, ...chartColoring });
}

function colorSeriesGroup(colors: string[], series?: ChartSeries[], offset = 0): ChartSeries[] {
	if (!series) {
		return [];
	}
	return series.map((s, index) => {
		return { ...s, color: colors[(index + offset) % colors.length] };
	});
}

/*function colorOrderedComponents(
	colors: string[],
	orderedSeries?: ChartSeries[],
	orderedComponents?: string[]
): [ChartSeries[], number] {
	if (!orderedSeries || !orderedComponents) {
		return [[], 0];
	}

	const orderedColors = orderedComponents.map((s, index) => {
		return { name: s, color: colors[index % colors.length] };
	});

	const lastColoredIndex = orderedSeries.length
		? orderedComponents.findIndex((comp) => comp == orderedSeries.slice(-1)[0].name)
		: 0;
	return [
		orderedSeries.map((s) => {
			return { ...s, color: orderedColors?.find((nameToColor) => nameToColor.name == s.name)?.color };
		}),
		lastColoredIndex + 1,
	];
}*/

function calcColoredSeriesForWaterfall({
	chartOptions,
	mainSeriesColors,
}: Pick<MetricDerivedState, 'chartOptions'> & ChartColoring): Pick<MetricDerivedState, 'chartOptions'> {
	const mainSeries = chartOptions.series.filter((s) => s.custom.seriesType == 'main');
	const coloredMainSeries = colorSeriesGroup(mainSeriesColors, mainSeries);
	const componentSeries = chartOptions.series.filter((s) => s.custom.seriesType != 'main');
	const coloredSeries = componentSeries.map((series) => {
		const value = series.data.at(0)?.y;
		const getColorByValue = (value: number | undefined) => {
			if (value === undefined) {
				return colors.gray['500'];
			}
			return value < 0 ? colors.red['600'] : colors.emerald['600'];
		};
		return {
			...series,
			color: getColorByValue(value),
		};
	});

	return {
		chartOptions: {
			...chartOptions,
			series: [...coloredSeries, ...coloredMainSeries],
		},
	};
}

function calcDefaultColoredSeries({
	chartOptions,
	mainSeriesColors,
	componentSeriesColors,
}: Required<Pick<MetricDerivedState, 'chartOptions'>> & ChartColoring): Pick<MetricDerivedState, 'chartOptions'> {
	const isPrimaryTimeSpan = ({ id }: ChartSeries): boolean => !id || id.startsWith(ID_PREFIX_SERIES_TIME_SPAN_PRIMARY);
	const primarySeriesList = chartOptions.series.filter(isPrimaryTimeSpan);
	const rotatingColorToIndex = (colorList: string[], index: number) => colorList[index % colorList.length];

	const colorMappings = Object.fromEntries([
		// Main series
		...primarySeriesList.filter(matches(mainSeriesCustomField)).flatMap(({ id = '', chartType }, position) => {
			const color = rotatingColorToIndex(mainSeriesColors, position);
			return [
				[id, color],
				[
					id?.replace(/^[^_]+/, ID_PREFIX_SERIES_TIME_SPAN_SECONDARY),
					chartType === 'column' ? patternColor(color, PATTERN_DIAGONAL_LINES) : color,
				],
			];
		}),

		// Breakdowns and Components
		...primarySeriesList.filter(matches(componentSeriesCustomField)).flatMap(({ id = '', chartType }, position) => {
			const color = rotatingColorToIndex(componentSeriesColors, position);
			return [
				[id, color],
				[
					id?.replace(/^[^_]+/, ID_PREFIX_SERIES_TIME_SPAN_SECONDARY),
					chartType === 'column' ? patternColor(color, PATTERN_DIAGONAL_LINES) : color,
				],
			];
		}),

		// Statistic Series (1.0 Feature scheduled for deprecation in early 2025)
		...primarySeriesList
			.filter(matches(statisticSeriesCustomField))
			.map(({ id = '', name }) => [
				id,
				ORDERED_STATISTIC_OPERATIONS[name as keyof typeof ORDERED_STATISTIC_OPERATIONS].color ?? mainSeriesColors.at(0),
			]),
	]);

	return {
		chartOptions: {
			...chartOptions,
			series: chartOptions.series.map(({ id = '', ...series }) => ({
				id,
				...series,
				color: colorMappings[id],
				dashStyle: id.startsWith(ID_PREFIX_SERIES_TIME_SPAN_SECONDARY) ? 'Dash' : 'Solid',
			})),
		},
	};
}

export function calcColoredBubbles(chartOptions: ChartOptions): Pick<MetricDerivedState, 'chartOptions'> {
	const coloredBubbles = chartOptions.bubbles?.map((bubble) => {
		const color = ORDERED_STATISTIC_OPERATIONS[bubble.name as StatisticalOperation].color;
		return { ...bubble, color };
	});

	return { chartOptions: { ...chartOptions, bubbles: coloredBubbles } };
}
