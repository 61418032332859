import { emptyChartOptions } from '@components/Chart/types';
import { RelativePeriodRange } from '@sightfull/period-ranges';
import { Atom, atom } from 'jotai';
import { MetricOperator } from 'src/models/MetricOperator';
import { fiscalYearOffset } from 'src/models/MetricPeriod/fiscalYear';
import { displayUnits, MetricDerivedState } from 'src/pages/MetricPage/utils/state.types';

export const loadingDerivedState: MetricDerivedState = {
	// TODO: Fix nullability of this object, for example in real metricstate, metric info is not nullable
	// PUL-2353
	isRulesEngineRunning: false,
	availableTargets: [],
	hasComponents: false,
	hasGroupBy: false,
	isAllComponentSameUnit: false,
	metricOperator: new MetricOperator('*'),
	breakdowns: { isDisabled: false, values: [] },
	filters: [],
	parameters: [],
	collectedProps: [],
	displayedLegendItems: { optionalValues: [], selectedValues: [] },
	flavor: { selectedValue: '', optionalValues: [''] },
	isExecutiveView: false,
	metricNameWithoutFlavor: '',
	metricNameWithFlavor: '',
	metricDisplayName: '',
	metricSource: '',
	periodRange: new RelativePeriodRange(
		{ startShift: 0, endShift: 0, startUnit: 'month', endUnit: 'month' },
		'month',
		fiscalYearOffset()
	),
	selectedXAxisElements: [],
	chartType: { selectedValue: 'column', optionalValues: ['column'] },
	metricExplanationOneliner: '',
	isLoading: true,
	objectsTypes: [],
	statisticsOperations: [],
	availableRangePresets: {},
	availablePeriodRanges: {},
	displayUnits: Object.fromEntries(
		displayUnits.map((option) => [
			option,
			{
				isDisabled: true,
				value: false,
			},
		])
	) as MetricDerivedState['displayUnits'],
	tableColDefs: [],
	tableRowsData: [],
	tableType: undefined,
	chartOptions: emptyChartOptions,
	isGranularDataForAllPeriodsButtonEnabled: false,
	decimalDigits: 1,
	sortOrder: { selectedValue: 'Label A to Z', optionalValues: ['Label A to Z'] },
	isFormula: undefined,
	metricYamlEditorMetricDefinitionJson: undefined,
	doesMetricExist: false,
	isFullyDefined: true,
	missingDependencies: [],
	yAxisConfig: undefined,
	compareToUnit: undefined,
	compareToUnitEffective: undefined,
};

export const DerivedStateAtom = atom(loadingDerivedState);

export function getInitialDerivedStateAtom(): readonly [Atom<MetricDerivedState>, MetricDerivedState] {
	return [DerivedStateAtom, loadingDerivedState];
}
