import { gql } from '@apollo/client';

export const CalculateMetricCoreReader = gql`
	input Filter {
		key: String!
		values: [String!]!
	}

	query CalculateMetricCoreReader(
		$metricName: String!
		$start: String!
		$end: String!
		$groupBy: [String!]
		$compareTo: CompareToInput
		$filterBy: [Filter!]
		$parameters: [ParameterValue!]
		$userMetricDefinitions: JSON
	) {
		calcMetricV2(
			metricName: $metricName
			start: $start
			end: $end
			groupBy: $groupBy
			filterBy: $filterBy
			compareTo: $compareTo
			parameters: $parameters
			userMetricDefinitions: $userMetricDefinitions
		) {
			dialect
			sql
			chartOptions {
				series {
					chartType
					data {
						custom {
							count
							percentage
						}
						name
						y
						secondaryY
						secondaryDelta
						secondaryGrowth
						secondaryName
					}
					name
					yAxis
					custom {
						rawName
						cleanName
						seriesType
						unit
						value
						appliedParameters {
							key
							value
						}
						parametersOverride {
							key
							value
						}
					}
				}
				xAxis {
					id
					label
				}
				hasGroupBy
				metricType
				availableChartTypes
				filters {
					key
					label
					values
					operator
					type
				}
				breakdowns {
					key
					label
				}
			}
			metricInfo {
				metricName
				isFormula
				description
				entity
				meta
				source
				isFullyDefined
				parameters {
					name
					label
					type
					description
					values_type
					default
					filePath
					values {
						__typename
						... on RangeValues {
							range {
								from
								to
								step
							}
						}
						... on RangeValue {
							from
							to
						}
						... on ListValues {
							listValues {
								value
								label
							}
						}
						... on RangeValue {
							from
							to
							step
						}
					}
				}
				missingDependencies {
					metrics {
						name
						displayName
					}
					entities {
						name
						displayName
					}
					dimensions {
						name
						displayName
						entityName
					}
					relationships {
						name
						displayName
						entityName
					}
				}
			}
			errorMessage
		}
	}
`;

export const CalculateDetailedMetricCoreReader = gql`
	input OrderByDetails {
		column: String!
		direction: String!
	}

	query CalculateDetailedMetricCoreReader(
		$metricName: String!
		$start: String!
		$end: String!
		$filterBy: [Filter!]
		$parameters: [ParameterValue!]
		$additionalColumns: [String!]
		$userMetricDefinitions: JSON
		$itemsPerPage: Float
		$pageOffset: Float
		$orderBy: [OrderByDetails!]
	) {
		calcDetailedTable(
			metricName: $metricName
			start: $start
			end: $end
			filterBy: $filterBy
			parameters: $parameters
			additionalColumns: $additionalColumns
			userMetricDefinitions: $userMetricDefinitions
			itemsPerPage: $itemsPerPage
			pageOffset: $pageOffset
			orderBy: $orderBy
		) {
			rows {
				id
				cols
			}
			cols {
				colName
				colDisplayName
				seriesFormatterKey
				aggregationFunction
				appliedParameters {
					key
					value
				}
			}
		}
	}
`;

export const MetricCatalogV2 = gql`
	query MetricCatalogV2 {
		metricCatalogV2 {
			categories {
				categoryId
				categoryName
				description
				isFullyDefined
				metrics {
					metricId
					metricName
					entity
					meta {
						displayName
						description
						oneLiner
						hidden
						source
						isNew
					}
					isFullyDefined
				}
				isFullyDefined
			}
			isFullyDefined
		}
	}
`;

export const EntityCatalog = gql`
	query EntityCatalog {
		entityCatalog {
			displayName
			description
			filePath
			entityName
			isDefined
			isFullyDefined
			affectedMetricsList
			schema {
				sourceDisplayName
				sourceURL
				sourceImage
				key
			}
		}
	}
`;

export const EntitySourceInfo = gql`
	query EntitySourceInfo($entity: String!) {
		entityInfo(entity: $entity) {
			schema {
				sourceDisplayName
				sourceURL
				sourceImage
			}
		}
	}
`;

export const GetRecordSearchEntities = gql`
	query GetRecordSearchEntities {
		getRecordSearchEntities {
			recordSearchEntities {
				entityName
				entityDisplayName
			}
		}
	}
`;

export const GetRecordSearchResults = gql`
	query GetRecordSearchResults($searchTerm: String!, $entityNames: [String!]) {
		getRecordSearchResults(searchTerm: $searchTerm, entityNames: $entityNames) {
			searchResults {
				entityName
				entityDisplayName
				records
			}
		}
	}
`;

export const RelationshipsAndDimensions = gql`
	query RelationshipsAndDimensions($entities: [String!]!) {
		relationshipsAndDimensions(entities: $entities) {
			entities {
				relationships {
					entity
					name
					description
					identifier
					is_normalized
					type
					affectedMetrics
					affectedMetricsList
				}
				dimensions {
					entity
					name
					description
					identifier
					is_normalized
					type
					affectedMetrics
					affectedMetricsList
				}
				entityName
			}
		}
	}
`;

export const EntityDimensionValues = gql`
	query EntityDimensionValues($entity: String!, $dimension: String!, $filter: String, $itemsPerPage: Float) {
		entityDimensionValues(entity: $entity, dimension: $dimension, filter: $filter, itemsPerPage: $itemsPerPage)
	}
`;

export const GetSemantics = gql`
	query GetSemantics {
		getSemantics
	}
`;

export const GetMetricTokensDisplayNames = gql`
	query GetMetricTokensDisplayNames($metricName: String!, $tokenNames: [String!]!) {
		getMetricTokensDisplayNames(metricName: $metricName, tokenNames: $tokenNames) {
			tokenDisplayNames
		}
	}
`;

export const GetTenantInfo = gql`
	query GetTenantInfo {
		tenantInfo {
			fiscalYearOffset
			lookbackMonths
			decimalDigits
			name
			logoUrl
			graphColors
			lastEtlSynced
			trialEnd
			schemasVersion
			tenantState
			plan
			isSightfullTenant
		}
	}
`;
export const CacheMetricParams = gql`
	mutation CacheMetricParams($uuid: String!, $userMetricDefinitions: JSON!) {
		cacheMetricParams(uuid: $uuid, userMetricDefinitions: $userMetricDefinitions) {
			status
		}
	}
`;

export const GetSchemasData = gql`
	query SchemasData {
		schemasData {
			name
			meta {
				sourceName
				__typename
			}
			tables {
				name
				columns {
					name
					dataType
					__typename
				}
				__typename
			}
			__typename
		}
	}
`;
