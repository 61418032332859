import * as React from 'react';
import { Sort16, SortAToZ16, SortDown16, SortUp16, SortZToA16 } from '@icons/index';
import { AppliedParameterValue } from '../../../../common/components/Chart/types';

export interface MetricComponent {
	id?: string;
	color: React.CSSProperties['color'];
	label?: string;
	appliedParameters?: AppliedParameterValue[];
	value: string;
	metricName: string;
	parametersOverride?: AppliedParameterValue[];
	isChecked: boolean;
}
export interface LegendPanelData {
	mainSeries: MetricComponent[];
	components: MetricComponent[];
	title: string;
	isBrokenDown: boolean;
}

export type SortOrderInfo = {
	icon: React.ReactNode;
	label: string;
};

export const ManualSortOrders = ['Manual'] as const;
export type ManualSortOrder = typeof ManualSortOrders[number];
export const SinglePeriodSortOrders = ['Value large to small', 'Value small to large'] as const;
export type SinglePeriodSortOrder = typeof SinglePeriodSortOrders[number];
export const MultiPeriodSortOrders = ['Label A to Z', 'Label Z to A'] as const;
export type MultiPeriodSortOrder = typeof MultiPeriodSortOrders[number];
export const AutomaticSortOrders = [...SinglePeriodSortOrders, ...MultiPeriodSortOrders] as const;
export type AutomaticSortOrder = typeof AutomaticSortOrders[number];
export const isAutomaticSortOrder = (x: any): x is AutomaticSortOrder => AutomaticSortOrders.includes(x);

export type SortOrder = AutomaticSortOrder | ManualSortOrder | SinglePeriodSortOrder | MultiPeriodSortOrder | 'Default';
export const SupportedSortOrders: readonly SortOrder[] = [...ManualSortOrders, ...AutomaticSortOrders] as const;
export const sortOrderToInfo: { [key in SortOrder]: SortOrderInfo } = {
	Default: { icon: <Sort16 />, label: 'Default' },
	Manual: { icon: <Sort16 />, label: 'Manual' },
	'Value large to small': { icon: <SortDown16 />, label: 'Value large to small' },
	'Value small to large': { icon: <SortUp16 />, label: 'Value small to large' },
	'Label A to Z': { icon: <SortAToZ16 />, label: 'Label A to Z' },
	'Label Z to A': { icon: <SortZToA16 />, label: 'Label Z to A' },
} as const;
