import { ReactNode, useEffect, useState } from 'react';
import { BubbleSeries as BubbleSeriesElement } from 'react-jsx-highcharts';
import { BubbleData, BubbleSeries, ChartOptions } from './types';
import { DisplayLegendItems } from 'src/pages/MetricPage/utils/state.types';

const useBubbles = (
	options: ChartOptions,
	maxYvalue: number | null,
	displayedLegendItems: DisplayLegendItems,
	isSinglePeriod: boolean
) => {
	const [bubbles, setBubbles] = useState<ReactNode[]>([]);

	useEffect(() => {
		const transformToBubble = (bubbleSeries: BubbleSeries, multiplier: number, maxYvalue: number | null) => {
			const data = bubbleSeries.dataPoints.map((bubble: BubbleData, bubbleXAxisIndex: number) => {
				const maxYValueNormalized = maxYvalue ?? 1;
				const value = bubble.label;
				const yWithGap = maxYValueNormalized * 0.3 + maxYValueNormalized;
				const count = !multiplier ? 1 : multiplier;
				const y = multiplier > 0 ? yWithGap + (maxYValueNormalized / 2) * count : yWithGap;
				const visibleSeriesLength = options.series.filter((s) => s.visible && !s.custom.isTarget).length;
				const xValue = isSinglePeriod ? visibleSeriesLength - 1 : bubbleXAxisIndex;
				const isBubbleTheOnlyElements = isSinglePeriod && visibleSeriesLength == 0;
				const name = isBubbleTheOnlyElements ? bubble.id : undefined;
				const x = isBubbleTheOnlyElements ? undefined : xValue;

				return {
					color: bubbleSeries.color,
					name,
					x,
					y,
					id: bubble.id,
					custom: {
						value,
					},
				};
			});

			return {
				type: 'bubble',
				id: bubbleSeries.type,
				name: bubbleSeries.name,
				custom: bubbleSeries.custom,
				data,
				getExtremesFromAll: true,
				marker: {
					fillOpacity: 0.2,
				},
			};
		};

		if (!options.bubbles) return;

		const newBubbles = options.bubbles.map((bubble: BubbleSeries, index: number) => {
			const newBubble = transformToBubble(bubble, index, maxYvalue);
			return <BubbleSeriesElement key={index} {...newBubble} />;
		});

		setBubbles(newBubbles);
	}, [maxYvalue, displayedLegendItems.selectedValues, options, displayedLegendItems, isSinglePeriod]);

	return bubbles;
};

export default useBubbles;
