import { ChartSeries, ID_PREFIX_SERIES_TIME_SPAN_PRIMARY } from '@components/Chart/types';
import { MetricPeriod } from '@sightfull/period-ranges';
import { ColDef, ColumnState, ValueFormatterParams } from 'ag-grid-community';
import omit from 'lodash/omit';
import { CalcMetricCallback } from 'src/common/hooks/fetching/useMetricApi';
import {
	buildDetailedTableFromResponse,
	DetailedTableIdCol,
	DetailedTableIdCollectedProps,
	DetailedTableIdGroupByV1,
} from 'src/common/utils/bizapiParsers';
import { getDimensionKey, getFiltersForQuery, getFormattingHandlerByName } from 'src/lib/metricRules/utils';
import { AverageOp, CountOp, DivideOp, MultiplyOp, SumOp } from 'src/models/MetricOperator';
import {
	getDimensionNameByKey,
	getRelationshipNameByKey,
} from 'src/pages/MetricPage/components/FiltersAndBreakdown/NodeScheme/utils';
import { DisplayLegendItems, MetricDerivedState, PulseColDef } from 'src/pages/MetricPage/utils/state.types';
import { MetricCalcRawResult, MultiPeriodDetailedTable, TotalSeriesName } from 'src/types/metric';
import { DataLabelFormatConfig } from '../statisticOperations/types';
import { getSinglePeriodSeries, isSinglePeriodView } from './calcSinglePeriodView';

export async function calcTable(
	derivedState: Pick<
		MetricDerivedState,
		| 'metricNameWithFlavor'
		| 'metricNameWithoutFlavor'
		| 'selectedXAxisElements'
		| 'collectedProps'
		| 'chartOptions'
		| 'filters'
		| 'breakdowns'
		| 'metricOperator'
		| 'periodRange'
		| 'metricDisplayName'
		| 'flavor'
		| 'metricInfo'
		| 'tableType'
		| 'availableTargets'
		| 'displayedLegendItems'
		| 'statisticsOperations'
		| 'objectsTypes'
	> &
		Partial<Pick<MetricDerivedState, 'tableColDefs' | 'tableRowsData'>>,
	formatConfig: DataLabelFormatConfig,
	executeInternalCalcMetric: CalcMetricCallback
): Promise<Pick<MetricDerivedState, 'tableColDefs' | 'tableRowsData'>> {
	const { tableType, periodRange, breakdowns, chartOptions, objectsTypes } = derivedState;
	const isSinglePeriod = isSinglePeriodView(periodRange, breakdowns, chartOptions.series);

	if (tableType == 'MetricTable') return calcMetricTable(derivedState, formatConfig);
	const mainNodeType = objectsTypes.join(' & ');

	if (tableType == 'DetailedTable')
		return await calcDetailedTable(derivedState, executeInternalCalcMetric, isSinglePeriod, mainNodeType);
	if (tableType == 'MultiPeriodDetailedTable')
		return await calcMultiPeriodDetailedTable(derivedState, executeInternalCalcMetric, mainNodeType);

	return { tableColDefs: [], tableRowsData: [] };
}

const calcStatisticColDefs = (
	{
		chartOptions,
		statisticsOperations,
		metricDisplayName,
	}: Pick<MetricDerivedState, 'chartOptions' | 'metricDisplayName' | 'statisticsOperations'>,
	formatConfig: DataLabelFormatConfig
) => {
	const statisticsColDef: ColDef[] = statisticsOperations
		.filter((operation) => operation.isChecked)
		.map((operation) => {
			const selectedElement = operation.options[operation.selectedOptionIndex];
			const label = selectedElement.label || selectedElement.value;
			const field = operation.name;
			const headerName = `${metricDisplayName}, ${field} ${label}`;

			return {
				field,
				headerName,
				valueFormatter: (params: ValueFormatterParams) => {
					const formatter = getFormattingHandlerByName(operation.name, chartOptions, formatConfig);
					if (!formatter) return params.value;
					return formatter(params.value);
				},
			};
		});

	return statisticsColDef;
};

export function calcMetricTable(
	{
		chartOptions,
		displayedLegendItems,
		statisticsOperations,
		metricDisplayName,
		tableColumnState,
	}: Pick<
		MetricDerivedState,
		'chartOptions' | 'displayedLegendItems' | 'metricDisplayName' | 'statisticsOperations' | 'tableColumnState'
	>,
	formatConfig: DataLabelFormatConfig
): Pick<MetricDerivedState, 'tableColDefs' | 'tableRowsData'> {
	const statisticsColDef = calcStatisticColDefs(
		{
			chartOptions,
			statisticsOperations,
			metricDisplayName,
		},
		formatConfig
	);

	const tableSeriesRowsData = chartOptions?.xAxis.values.map((value) => {
		if (!chartOptions || !chartOptions.series) return {};
		const legend = chartOptions?.xAxis.formatter(value);

		const legendComponents = chartOptions.series.map((series) => {
			const seriesValue = series.data.find((dataPoint) => dataPoint.name == legend)?.y;

			return [series.name, seriesValue];
		});

		const statisticsComponents = chartOptions.bubbles?.map((series) => {
			const seriesValue = series.dataPoints.find((dataPoint) => dataPoint.id == legend)?.label;

			return [series.name, seriesValue];
		});
		return {
			xAxisValue: value,
			Legend: legend,
			...Object.fromEntries(legendComponents ?? []),
			...Object.fromEntries(statisticsComponents ?? []),
		};
	}) ?? [{}];

	const metricColDefs: ColDef[] =
		chartOptions?.series
			?.flatMap((s) => {
				return s.id?.startsWith(ID_PREFIX_SERIES_TIME_SPAN_PRIMARY)
					? [
							{
								field: s.name,
								headerName: s.name,
								headerComponentParams: { appliedParameters: s.custom.appliedParameters },
								valueFormatter: (params: ValueFormatterParams) => {
									const formatter = s.custom?.seriesDataPointYFormatter;
									if (!formatter) return params.value;
									return formatter(params.value);
								},
							},
					  ]
					: [];
			})
			.map((colDef) => setColDefVisibility(colDef, displayedLegendItems)) ?? [];
	const sortedSeriesColDef = metricColDefs.sort((a, b) => calcColDefOrder(a, b, chartOptions.series));

	const unsortedColDefs: PulseColDef[] = [
		{ field: 'Legend', headerName: 'Period', pinned: 'left' },
		...sortedSeriesColDef,
		...statisticsColDef,
	];
	const tableColDefs = sortByTableColumnState(unsortedColDefs, tableColumnState);
	const tableRowsData = [...tableSeriesRowsData];

	return { tableColDefs, tableRowsData };
}

function buildCollectedPropHeaderName(k: string) {
	const relationshipName = getRelationshipNameByKey(k);
	const dimensionName = getDimensionNameByKey(getDimensionKey(k));
	return relationshipName ? relationshipName + "'s " + dimensionName : dimensionName;
}

function buildCollectedPropColDef(prop: string, fieldPrefix = ''): PulseColDef {
	const headerName = buildCollectedPropHeaderName(prop);

	return {
		field: fieldPrefix + prop,
		headerName,
		filterKey: prop,
		valueFormatter: (v) => v.data?.[prop]?.toString(),
	};
}

function buildBreakdownsColDefs(breakdowns: MetricDerivedState['breakdowns'], fieldPrefix = ''): PulseColDef[] {
	return breakdowns.values.map(({ key, label }) => {
		return {
			// TODO: this is a hacky solution for Guy's Demo: https://sightfull.slack.com/archives/C01ED54CZQU/p1696338712337859 - We need to remove this
			field: fieldPrefix + key.replaceAll('[', '.').replaceAll(']', ''),
			headerName: label,
			expanded: true,
			rowGroup: true,
			hide: true,
		};
	});
}

async function calcDetailedTable(
	{
		metricNameWithFlavor,
		selectedXAxisElements,
		collectedProps,
		chartOptions,
		filters,
		breakdowns,
		metricOperator,
		metricDisplayName,
		displayedLegendItems,
	}: Pick<
		MetricDerivedState,
		| 'metricNameWithFlavor'
		| 'selectedXAxisElements'
		| 'collectedProps'
		| 'chartOptions'
		| 'filters'
		| 'breakdowns'
		| 'metricOperator'
		| 'periodRange'
		| 'metricDisplayName'
		| 'flavor'
		| 'metricInfo'
		| 'displayedLegendItems'
	>,
	executeInternalCalcMetric: CalcMetricCallback,
	isSinglePeriod: boolean,
	mainNodeType: string
): Promise<Pick<MetricDerivedState, 'tableColDefs' | 'tableRowsData'>> {
	if (!(selectedXAxisElements?.[0] instanceof MetricPeriod)) return { tableColDefs: [], tableRowsData: [] };

	const queryPeriodId = selectedXAxisElements[0].id;
	const response = await executeInternalCalcMetric(metricNameWithFlavor, [queryPeriodId], {
		// TODO: replace with useDetailedTableApi
		filter_by: getFiltersForQuery(filters),
		group_by: [DetailedTableIdGroupByV1],
		collect_props: [...breakdowns.values.map((b) => b.key), ...collectedProps, DetailedTableIdCol],
	});

	const tableRowsData = buildDetailedTableFromResponse(response[queryPeriodId]);

	const rawTableColDefs: ColDef[] = Object.keys(tableRowsData[0] ?? {})
		.filter((v) => v != DetailedTableIdGroupByV1 && v != DetailedTableIdCol && v != DetailedTableIdCollectedProps)
		.map((k) => {
			const isMainSeries = k == TotalSeriesName;
			const unit = 'value';
			const legendHeaderName = isMainSeries ? k.replaceAll(TotalSeriesName, metricDisplayName) : k;
			const aggFunc = isMainSeries
				? aggregationFunctionByOperator(metricOperator.op)
				: breakdowns.values.length == 0
				? aggregationFunctionByOperator(
						chartOptions.series.find((currSeries) => currSeries.name == legendHeaderName)?.custom.op?.op
				  )
				: undefined;
			return {
				field: `${k}.${unit}`,
				headerName: legendHeaderName,
				valueFormatter: (params: ValueFormatterParams) => {
					const findHandler = isMainSeries
						? (s: ChartSeries) => s.custom.seriesType == 'main'
						: (s: ChartSeries) => s.name == legendHeaderName;

					const possibleFormatter = isSinglePeriod
						? getSinglePeriodSeries(chartOptions.series)?.custom.seriesDataPointYFormatter
						: chartOptions?.series.find(findHandler)?.custom?.seriesDataPointYFormatter;

					if (!possibleFormatter) return params.value;
					return possibleFormatter(params.value);
				},
				aggFunc,
			};
		});

	const sortedSeriesColDef = rawTableColDefs.sort((a, b) => calcColDefOrder(a, b, chartOptions.series));

	const fieldPrefix = DetailedTableIdCollectedProps + '.';
	const breakdownsColDefs = buildBreakdownsColDefs(breakdowns, fieldPrefix);
	const collectPropsColDefs: PulseColDef[] = collectedProps.map((prop) => buildCollectedPropColDef(prop, fieldPrefix));

	const tableColDefsWithHeaderName = [...sortedSeriesColDef, ...breakdownsColDefs, ...collectPropsColDefs];

	const colDefsWithVisibility = tableColDefsWithHeaderName.map((colDef) =>
		setColDefVisibility(colDef, displayedLegendItems)
	);

	const tableColDefs: PulseColDef[] = [
		{
			field: DetailedTableIdCol,
			pinned: 'left',
			headerName: `${mainNodeType}'s ${DetailedTableIdCol}`,
			aggFunc: 'count',
		},
		...colDefsWithVisibility,
	];

	return {
		tableColDefs,
		tableRowsData,
	};
}

async function calcMultiPeriodDetailedTable(
	{
		metricNameWithFlavor,
		metricNameWithoutFlavor,
		selectedXAxisElements,
		collectedProps,
		chartOptions,
		filters,
		metricOperator,
		breakdowns,
	}: Pick<
		MetricDerivedState,
		| 'metricNameWithFlavor'
		| 'metricNameWithoutFlavor'
		| 'selectedXAxisElements'
		| 'collectedProps'
		| 'chartOptions'
		| 'filters'
		| 'metricOperator'
		| 'breakdowns'
	>,
	executeInternalCalcMetric: CalcMetricCallback,
	mainNodeType: string
): Promise<Pick<MetricDerivedState, 'tableColDefs' | 'tableRowsData'>> {
	function isMetricPeriodArray(array: unknown[]): array is MetricPeriod[] {
		return array.every((e) => e instanceof MetricPeriod);
	}

	if (!isMetricPeriodArray(selectedXAxisElements)) return { tableColDefs: [], tableRowsData: [] };

	let rowsDataObject: Record<string, any> = {};

	const queryPeriodIds = selectedXAxisElements.map((e) => e.id);
	const response = await executeInternalCalcMetric(metricNameWithFlavor, queryPeriodIds, {
		filter_by: getFiltersForQuery(filters),
		group_by: [DetailedTableIdGroupByV1],
		collect_props: [...breakdowns.values.map((b) => b.key), ...collectedProps, DetailedTableIdCol],
	});
	rowsDataObject = Object.entries(response).reduce(
		(accumulator, [periodId, value]) => buildMultiPeriodDetailedTableFromResponse(value, periodId, accumulator),
		{}
	);

	const aggFunc = aggregationFunctionByOperator(metricOperator.op);
	const mainSeriesFormatter = chartOptions?.series.find((s) => s.name == metricNameWithoutFlavor)?.custom
		?.seriesDataPointYFormatter;
	const periodsFieldsDefinition: ColDef[] = selectedXAxisElements.map((e) => {
		return {
			field: e.id,
			headerName: e.pretty,
			valueFormatter: (params: ValueFormatterParams) => {
				if (!params.value) return '';

				if (!mainSeriesFormatter) return params.value;
				return mainSeriesFormatter(params.value);
			},
			aggFunc,
		};
	});

	const breakdownsColDefs = buildBreakdownsColDefs(breakdowns);
	const collectedPropsColumnDefinition: ColDef[] = collectedProps.map((p) => buildCollectedPropColDef(p));

	return {
		tableColDefs: [
			{
				field: DetailedTableIdCol,
				filterKey: mainNodeType + '.' + DetailedTableIdCol,
				aggFunc: 'count',
				pinned: 'left',
			},
			...periodsFieldsDefinition,
			...breakdownsColDefs,
			...collectedPropsColumnDefinition,
		],
		tableRowsData: Object.values(rowsDataObject),
	};
}

export const setColDefVisibility = (colDef: ColDef, displayedLegendItems: DisplayLegendItems) => {
	const isCollectedProp = colDef.field?.includes(DetailedTableIdCollectedProps);
	const hide =
		!isCollectedProp && !displayedLegendItems.selectedValues.includes(colDef?.headerName || colDef?.field || '');

	return {
		hide,
		...colDef,
	};
};

const sortByTableColumnState = (colDefs: ColDef<any>[], tableColumnState?: ColumnState[]): PulseColDef[] => {
	if (!tableColumnState) return colDefs;

	const colIndex = (col: ColDef) => tableColumnState?.findIndex((column) => column.colId == col.field) ?? -1;
	return [...colDefs].sort((c1, c2) => colIndex(c1) - colIndex(c2));
};

const calcColDefOrder = (colDefA: ColDef, colDefB: ColDef, series: ChartSeries[]) => {
	const colDefASeriesIndex =
		series.find((s) => s.name === (colDefA.headerName || colDefA.field))?.custom.seriesOrder ?? -1;
	const colDefBSeriesIndex =
		series.find((s) => s.name === (colDefB.headerName || colDefB.field))?.custom.seriesOrder ?? -1;

	return colDefASeriesIndex - colDefBSeriesIndex;
};

function buildMultiPeriodDetailedTableFromResponse(
	response: MetricCalcRawResult,
	periodId: string,
	accumulator: MultiPeriodDetailedTable
): MultiPeriodDetailedTable {
	const rawRows = response.result;

	rawRows
		.filter((rawRow): rawRow is [key: string, data: any] => rawRow[0] != null)
		.forEach((rawRow) => {
			const rowId = rawRow[0][0];
			const objectName = rawRow[1].collected.Name[0];
			const collectedProps = omit(rawRow[1].collected, 'Name');

			accumulator[rowId] = {
				...accumulator[rowId],
				[DetailedTableIdGroupByV1]: rowId,
				[DetailedTableIdCol]: objectName,
				[periodId]: rawRow[1].breakdown[TotalSeriesName],
				...collectedProps,
			};
		});
	return accumulator;
}

function aggregationFunctionByOperator(operator: string | undefined) {
	switch (operator) {
		case CountOp.op:
			return 'count';
		case SumOp.op:
			return 'sum';
		case DivideOp.op:
		case MultiplyOp.op:
		case AverageOp.op:
			return 'avg';
		default:
			return 'count';
	}
}
