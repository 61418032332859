import {
	Box,
	Flex,
	FormControl,
	FormLabel,
	NumberDecrementStepper,
	NumberIncrementStepper,
	NumberInput,
	NumberInputField,
	NumberInputStepper,
} from '@chakra-ui/react';
import { NumberStepperDown, NumberStepperUp } from '@icons/index';

export default function DecimalPicker({
	value = 1,
	onChange,
}: {
	value: number;
	onChange: (decimalDigits: number) => void;
}) {
	return (
		<Box key="decimal_format" textAlign="start" paddingLeft="16px" paddingRight="16px" borderColor="black">
			<FormControl>
				<Flex flexDirection="row" justifyContent={'space-between'} alignItems={'center'}>
					<FormLabel color="gray.1000" fontSize="0.875rem" m={0} p={0} fontWeight="normal">
						Decimals
					</FormLabel>
					<NumberInput
						fontSize="0.875rem"
						color="gray.1000"
						textAlign="end"
						width={'48px'}
						variant="ghost"
						size="inline"
						onChange={(_, num) => onChange(num)}
						max={3}
						min={0}
						value={value}
					>
						<NumberInputField readOnly={true} />
						<NumberInputStepper>
							<NumberIncrementStepper width={'16px'} height={'8px'} marginTop={'6px'} borderColor={'transparent'}>
								<NumberStepperUp />
							</NumberIncrementStepper>
							<NumberDecrementStepper width={'16px'} height={'8px'} marginBottom={'6px'} borderColor={'transparent'}>
								<NumberStepperDown />
							</NumberDecrementStepper>
						</NumberInputStepper>
					</NumberInput>
				</Flex>
			</FormControl>
		</Box>
	);
}
