import { MetricPeriod } from '@sightfull/period-ranges';
import { YAxisOptions } from 'highcharts';
import {
	ChartColumns16,
	ChartLines16,
	ChartPie16,
	ChartStackedColumns16,
	ChartWaterfall16,
	HashMark16,
} from 'src/common/components/Icons';
import { MetricOperator } from 'src/models/MetricOperator';
import { CoreMetricUnit, MetricUnit } from 'src/types/metric';

import * as React from 'react';

export type BubbleData = {
	id: string;
	label: string;
};

export type ChartTypeInfo = {
	icon: React.ReactNode;
	label: string;
};

export const SupportedChartTypes = [
	'line',
	'stackedColumn',
	'column',
	'waterfall',
	'pie',
	'number',
	'attainment',
] as const; // TODO: support 'table' in chart type
export const chartTypeToInfo: { [key: string]: ChartTypeInfo } = {
	line: { icon: <ChartLines16 />, label: 'Line' },
	stackedColumn: { icon: <ChartStackedColumns16 />, label: 'Stacked column' },
	column: { icon: <ChartColumns16 />, label: 'Column' },
	waterfall: { icon: <ChartWaterfall16 />, label: 'Waterfall' },
	pie: { icon: <ChartPie16 />, label: 'Pie' },
	number: { icon: <HashMark16 />, label: 'Number' },
	attainment: { icon: <HashMark16 />, label: 'Attainment' },
} as const;
export type ChartType = typeof SupportedChartTypes[number];
export type SeriesType = 'main' | 'statistic' | 'component';

export const mainSeriesCustomField: SeriesCustomField = { custom: { seriesType: 'main' } };
export const statisticSeriesCustomField: SeriesCustomField = { custom: { seriesType: 'statistic' } };
export const componentSeriesCustomField: SeriesCustomField = { custom: { seriesType: 'component' } };

export const ID_PREFIX_SERIES_TIME_SPAN_PRIMARY = 'pri';
export const ID_PREFIX_SERIES_TIME_SPAN_SECONDARY = 'sec';

export type AppliedParameterValue = {
	key: string;
	value: string;
};

export type MultiTooltipPeriodType = {
	id?: string;
	value: string | undefined;
	color: string | undefined | { pattern: { color: string } };
	name: string;
	appliedParameters?: AppliedParameterValue[];
};

export type MultiToolTipPropsType = {
	currPeriod?: string;
	bubbles?: MultiTooltipPeriodType[];
	currPeriodData?: MultiTooltipPeriodType[];
	onMouseLeave: () => void;
	mainTitles?: string[];
};

export type SeriesCustomField = {
	custom: {
		seriesType: SeriesType;
		isTarget?: boolean;
		seriesOrder?: number;
		unit?: MetricUnit | CoreMetricUnit;
		op?: MetricOperator | undefined;
		value?: number;
		rawName?: string;
		cleanName?: string;
		seriesDataPointYFormatter?: SeriesDataPointYFormatter;
		visible?: boolean;
		appliedParameters?: AppliedParameterValue[];
		parametersOverride?: AppliedParameterValue[];
	};
};

export type BubbleSeries = {
	type: string;
	name: string;
	dataPoints: BubbleData[];
	color?: string;
} & SeriesCustomField;

export type SeriesDataPointY = number | undefined;
export type SeriesDataPointCustom = Partial<{
	count: number;
	percentage: number;
	percentagePrev: number;
	percentageFirst: number;
	label: string; // TODO: remove this after core reader POC when formatting is back in the FE
}>;
export type SeriesDataPointObject = {
	y?: number;
	delta?: number;
	growth?: number;
	secondaryName?: string;
	name: string; // DOC: This is how highcharts marks which xAxis you are in
	isSum?: boolean;
	visible?: boolean;
	color?: string;
	custom?: SeriesDataPointCustom;
};
export type SeriesDataPoint = SeriesDataPointObject;
export type SeriesDataPointYFormatter = (yValue: SeriesDataPointY) => string;

export type XAxisOptions = {
	plotBands: Highcharts.XAxisPlotBandsOptions[];
	values: MetricPeriod[];
	formatter: (value: MetricPeriod | string) => string;
};

export type ChartSeries = {
	id?: string;
	chartType: ChartType;
	name: string;
	data: SeriesDataPoint[];
	color?: string;
	dashStyle?: 'Dash' | 'Solid';
	strokeWidth?: number;
	yAxis?: string;
	stack?: string;
	options?: {
		name: string;
	};
	visible?: boolean;
} & SeriesCustomField;

export type ChartOptions = {
	chart?: {
		height?: string;
	};
	series: ChartSeries[];
	bubbles?: BubbleSeries[];
	xAxis: XAxisOptions;
	yAxis: YAxisOptions[];
};

export const emptyChartOptions: ChartOptions = {
	series: [],
	xAxis: {
		plotBands: [],
		values: [],
		formatter: (p: any) => p,
	},
	yAxis: [],
};
