import { useCallback } from 'react';
import { MetricSearchParams } from 'src/common/utils/MetricSearchParams';
import { extractFlavor } from 'src/lib/metricRules/utils';
import { useMetricDerivedState } from 'src/pages/MetricPage/hooks/useMetricDerivedState';
import { useMetricPageSearchParamsSource } from '../atoms/MetricPageSearchParams';
import { useNormalizedSearchParams } from '../utils/useNormalizedSearchParams';

export function stringifySearchParams([key, value]: [string, any]) {
	if (typeof value === 'object') return [key, JSON.stringify(value)];
	return [key, value];
}

export function useMetricPageSearchParams() {
	const [{ metricName, searchParams }, rawSetMetricPageSearchParams] = useMetricPageSearchParamsSource();
	const { isRulesEngineRunning } = useMetricDerivedState();

	if (!metricName) throw Error('No metric name found in the url'); // TODO: I don't think throwing in hook is the right way to handle error, think about it

	const [metricNameWithoutFlavor, metricFlavor] = extractFlavor(metricName);
	if (!metricNameWithoutFlavor) throw Error('Error parsing the metric name into metricName and flavor');

	const normalizeSearchParams = useNormalizedSearchParams({ searchParams });

	const setSearchParams = useCallback(
		(searchParams: Partial<MetricSearchParams>) => {
			if (isRulesEngineRunning) {
				console.warn('Page is loading, action aborted');
				return;
			}
			const filteredParams = Object.entries(searchParams).filter(([, v]) => v != null);
			const normalizedParams = Object.fromEntries(filteredParams.map(stringifySearchParams));

			rawSetMetricPageSearchParams(new URLSearchParams(normalizedParams));
		},
		[isRulesEngineRunning, rawSetMetricPageSearchParams]
	);

	const setPartialSearchParams = useCallback(
		(partialSearchParams: Partial<MetricSearchParams>) => {
			setSearchParams({ ...normalizeSearchParams, ...partialSearchParams });
		},
		[normalizeSearchParams, setSearchParams]
	);

	return {
		searchParams: normalizeSearchParams,
		metricNameWithFlavor: metricName,
		metricNameWithoutFlavor: metricNameWithoutFlavor?.trim(),
		metricFlavor,
		setSearchParams,
		dangerousRawSearchParams: searchParams, // TODO: rethink this and all of it's usages, this is too DANGEROUS
		setPartialSearchParams,
	};
}

export function cleanSearchParams(searchParams: URLSearchParams) {
	searchParams.delete('_tenantId');
	clearPeriodRangeFromSearchParams(searchParams);
	removeEmptyFilters(searchParams);
}

const removeEmptyFilters = (searchParams: URLSearchParams) => {
	[...searchParams.entries()].forEach(([key, value]) => {
		if (key === 'filterBy') {
			const data = JSON.parse(value);

			const updatedData = Object.fromEntries(
				Object.entries(data).filter(([, val]) => Array.isArray(val) && val.length > 0)
			);

			searchParams.set(key, JSON.stringify(updatedData));
		} else {
			searchParams.set(key, value);
		}
	});
};

function clearPeriodRangeFromSearchParams(searchParams: URLSearchParams) {
	const periodSearchParamsToDelete = [
		'from',
		'to',
		'periodRange',
		'relativePeriodFrom',
		'relativePeriodTo',
		'relativePeriodDuration',
		'relativePeriod',
	];
	periodSearchParamsToDelete.map((s) => searchParams.delete(s));
}
