import {
	ApolloClient,
	ApolloLink,
	ApolloProvider,
	HttpLink,
	InMemoryCache,
	NormalizedCacheObject,
	split,
	useApolloClient,
} from '@apollo/client';
import { setContext } from '@apollo/client/link/context';
import { GraphQLWsLink } from '@apollo/client/link/subscriptions';
import { getMainDefinition } from '@apollo/client/utilities';
import { createClient as wsCreateClient } from 'graphql-ws';
import { ReactNode, useCallback, useEffect, useMemo } from 'react';
import useFeatureFlag from 'src/common/hooks/stores/useFeatureFlag';
import { useSemanticDefinitions } from 'src/common/hooks/stores/useSemanticDefinitions';
import { useFronteggAuth } from 'src/common/hooks/useFronteggLogin';
import { isProductionEnv } from 'src/config';
import { createAuthHeader, useAuthEnv } from 'src/services/auth';
import { normalizedEnvConfig } from 'src/stores/environment';
import { EnvironmentConfig } from '../types/environment';
import { initializeTenantInformationCoreReader } from './user';

export const createClient = ({
	selectedEnv,
	accessToken,
	isSightfull2,
	options,
}: {
	selectedEnv: EnvironmentConfig;
	accessToken: string;
	isSightfull2: boolean;
	options?: Record<string, any>;
}): ApolloClient<NormalizedCacheObject> => {
	const headers = {
		'X-Role': selectedEnv.role,
		'X-Tenant-ID': selectedEnv.tenant.toString(),
	};

	const wsLink = createWsLink(selectedEnv, headers, accessToken);

	const httpLink = new HttpLink({
		uri: selectedEnv.httpGqlUrl,
		fetch: options?.fetch,
	});

	const apolloRouterGqlLink = new HttpLink({
		uri: selectedEnv.apolloRouterGqlUrl,
		fetch: options?.fetch,
	});

	const httpLinkWithAuth = createAuthLink(httpLink, headers, accessToken);
	const apolloRouterGqlLinkWithAuth = createAuthLink(apolloRouterGqlLink, headers, accessToken);

	const link = split(
		({ query }) => {
			const definition = getMainDefinition(query);
			return definition.kind === 'OperationDefinition' && definition.operation === 'subscription';
		},
		wsLink,
		isSightfull2 ? apolloRouterGqlLinkWithAuth : httpLinkWithAuth
	);

	return new ApolloClient({
		link,
		connectToDevTools: !isProductionEnv,
		defaultOptions: {
			query: {
				fetchPolicy: 'no-cache',
			},
			watchQuery: {
				fetchPolicy: 'no-cache',
			},
		},
		cache: new InMemoryCache(),
	});
};

function createWsLink(
	selectedEnv: EnvironmentConfig,
	headers: { 'X-Role': string; 'X-Tenant-ID': string },
	accessToken: string
) {
	return new GraphQLWsLink(
		wsCreateClient({
			url: selectedEnv.wsGqlUrl,
			connectionParams: async () => createAuthHeader(headers, accessToken),
		})
	);
}

function createAuthLink(linkToConcat: ApolloLink, headers: Record<string, string>, accessToken: string): ApolloLink {
	// This is async link. which is good
	const authLink = setContext(async () => {
		return createAuthHeader(headers, accessToken);
	});

	return authLink.concat(linkToConcat);
}

export function PulseApolloProvider({ children }: { children: ReactNode }) {
	const isSightfull2 = useFeatureFlag('pulse.sightfull2.enable');
	const { user, isAuthenticated } = useFronteggAuth();
	const accessToken = user?.accessToken;
	const authEnv = useAuthEnv();

	const apolloClient = useMemo(() => {
		if (!isAuthenticated || !accessToken || !authEnv) return null;

		return createClient({ selectedEnv: normalizedEnvConfig(authEnv), accessToken, isSightfull2 });
	}, [accessToken, authEnv, isAuthenticated, isSightfull2]);

	useEffect(() => {
		// TA1.0: remove this and always use sightfull2
		if (!apolloClient || !isSightfull2) return;
		void initializeTenantInformationCoreReader(apolloClient);
	}, [apolloClient, isSightfull2]);

	if (!apolloClient) return null;

	return <ApolloProvider client={apolloClient}>{children}</ApolloProvider>;
}

function invalidateMetricDefinitionAffectedFieldsInApolloCache(apolloClient: ApolloClient<object>) {
	apolloClient.cache.evict({ id: 'ROOT_QUERY', fieldName: 'calcMetricV2' });
	apolloClient.cache.evict({ id: 'ROOT_QUERY', fieldName: 'calcMetric' });
	apolloClient.cache.evict({ id: 'ROOT_QUERY', fieldName: 'metricInfo' });
	apolloClient.cache.evict({ id: 'ROOT_QUERY', fieldName: 'metricCatalogV2' });
	apolloClient.cache.evict({ id: 'ROOT_QUERY', fieldName: 'entityCatalog' });
	apolloClient.cache.evict({ id: 'ROOT_QUERY', fieldName: 'getSemantics' });
	apolloClient.cache.evict({ id: 'ROOT_QUERY', fieldName: 'getSpecificMetricDefinition' });
	apolloClient.cache.evict({ id: 'ROOT_QUERY', fieldName: 'getEntityDefinition' });
	apolloClient.cache.evict({ id: 'ROOT_QUERY', fieldName: 'relationshipsAndDimensions' });
	apolloClient.cache.gc();
}

export function useInvalidateCache() {
	const client = useApolloClient();
	const { reloadSemantics } = useSemanticDefinitions();

	const invalidateCache = useCallback(() => {
		invalidateMetricDefinitionAffectedFieldsInApolloCache(client);
		void reloadSemantics();
	}, [client, reloadSemantics]);

	return { invalidateCache };
}
