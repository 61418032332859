import { atom, Atom, useAtom, useAtomValue } from 'jotai';
import { useCallback } from 'react';
import useParams from 'src/common/hooks/navigation/useParams';
import useSearchParams from 'src/common/hooks/navigation/useSearchParams';

export type MetricPageSearchParams = { metricName: string; searchParams: URLSearchParams };
export const MetricPageSearchParamsAtom = atom<MetricPageSearchParams>({
	metricName: '',
	searchParams: new URLSearchParams(),
});
export const IsMetricPageURLBasedAtom = atom<boolean>(false);

export function useMetricPageSearchParamsSource(): [MetricPageSearchParams, (params: URLSearchParams) => void] {
	const isMetricPageURLBasedAtom = useAtomValue(IsMetricPageURLBasedAtom);
	const [urlSearchParams, setUrlSearchParams] = useSearchParams();
	const { metricName } = useParams();
	const [metricPageSearchParamsAtomValue, setMetricPageSearchParamsAtom] = useAtom(MetricPageSearchParamsAtom);
	const setMetricPageSearchParamsAtomCallback = useCallback(
		(searchParams: URLSearchParams) => {
			setMetricPageSearchParamsAtom((s) => ({ ...s, searchParams }));
		},
		[setMetricPageSearchParamsAtom]
	);

	if (isMetricPageURLBasedAtom) {
		return [
			{
				searchParams: urlSearchParams,
				metricName: metricName ?? '',
			},
			(...s) => {
				setUrlSearchParams(...s);
			},
		];
	} else {
		return [metricPageSearchParamsAtomValue, setMetricPageSearchParamsAtomCallback];
	}
}

export function useDefaultMetricPageSearchParamsByUrl(): readonly [
	Atom<MetricPageSearchParams>,
	MetricPageSearchParams
] {
	const [searchParams] = useSearchParams();
	const { metricName } = useParams();

	return [MetricPageSearchParamsAtom, { searchParams, metricName: metricName ?? '' }];
}
