import Box from '@components/Box';
import Checkbox from '@components/Checkbox';
import Flex from '@components/Flex';
import ListItem from '@components/ListItem';
import { useCallback, useEffect, useMemo, useState } from 'react';
import Divider from 'src/common/components/Divider/Divider';
import IconButton from 'src/common/components/IconButton';
import { Legend16 } from 'src/common/components/Icons';
import Typography from 'src/common/components/Typography';
import { sleep } from 'src/common/utils/utils';
import {
	CountStatistic,
	PercentageFirstStatistic,
	PercentagePreviousStatistic,
	PercentageStatistic,
} from 'src/lib/metricRules/statisticOperations/constants';
import { useDisplayUnits } from 'src/pages/MetricPage/hooks/useDisplayedUnits';
import { useMetricDerivedState } from 'src/pages/MetricPage/hooks/useMetricDerivedState';
import useStatisticsOperations from 'src/pages/MetricPage/hooks/useStatisticsOperations';
import { useReportEvent } from 'src/services/analytics';
import useNavigation from 'src/services/useNavigation';
import shadows from 'src/style/shadows';
import { stringifySearchParams, useMetricPageSearchParams } from '../../hooks/useMetricPageSearchParams';
import { getFilterParams } from '../InvestigatePanel/useDimensionsState';
import { SkeletonLegendPanel } from '../SkeletonComponents';
import { LegendComponent, LegendSelectAll } from './ComponentLegends';
import { ExpandCollapseFooter, ExpandCollapseProps } from './ExpandCollapseFooter';
import classes from './LegendPanel.module.scss';

import ReactGridLayout from 'react-grid-layout';
import { isDefined } from 'src/normalize/utils';
import { ReorderModal } from 'src/pages/MetricPage/components/LegendsPanel/ReorderModal';
import { useSortOrder } from 'src/pages/MetricPage/hooks/useSortOrder';
import { LegendStats } from './StatisticsLegends';
import { DRAG_HANDLE_CLASS } from './constants';
import { MetricComponent } from './types';
import { componentsTitle, useDisplayedLegendState } from './useDisplayedLegendState';
import { buildMetricComponentKey } from './utils';
import DecimalPicker from './DecimalPicker';

type Props = {
	isLoading: boolean;
};

export function LegendPanel({ isLoading, isExpanded, setIsExpanded }: Props & ExpandCollapseProps) {
	const { reportEvent } = useReportEvent();
	const { searchParams, setPartialSearchParams } = useMetricPageSearchParams();
	const { manualReorder } = useSortOrder();

	const [legendState, { toggleLegend }] = useDisplayedLegendState();
	const [statisticOperationState, { toggleOperationState, handleOperationInfoChange }] = useStatisticsOperations();
	const { navigate } = useNavigation();

	const [displayUnits, toggleDisplayUnit] = useDisplayUnits();
	const { metricNameWithFlavor, breakdowns, metricDisplayName, availableTargets, decimalDigits } =
		useMetricDerivedState();

	const [isForceCollapse, setIsForceCollapse] = useState(false);
	const resetForceCollapseAfterAnimation = () =>
		sleep(300).then(() => {
			setIsForceCollapse(false);
		});
	useEffect(() => {
		if (!isForceCollapse) return;
		void resetForceCollapseAfterAnimation();
	}, [isForceCollapse]);

	const mainLegendsElements = legendState.mainSeries.map((series) => (
		<Box key={series.id}>
			<LegendComponent
				isFluidCheckBox
				component={series}
				onCheckChange={() => {
					toggleLegend(series.value);
					reportEvent({
						event: 'hiddenLegendNames-onTap',
						metaData: {
							metricName: metricNameWithFlavor,
							groupByList: breakdowns.values.map((o) => o.key),
							toggledLegend: series.value,
							'toggle-direction': series.isChecked ? 'turn-off' : 'turn-on',
							isTarget: availableTargets.includes(series.value),
						},
					});
				}}
			/>
		</Box>
	));

	const statsLegendsElements = statisticOperationState.map((stat) => (
		<Box key={stat.name}>
			<LegendStats
				stat={stat}
				onCheckChange={() => {
					toggleOperationState(stat);
					reportEvent({
						event: 'toggle-statistic',
						metaData: {
							metricName: metricNameWithFlavor,
							comparisonDuration: stat.options[stat.selectedOptionIndex].value,
							statistic: stat.name,
							'is-open': stat.isChecked,
						},
					});
				}}
				onStatModifierChange={(newVal) => {
					handleOperationInfoChange(stat, newVal);
					reportEvent({
						event: 'metric-statistics-change-comparison-duration',
						metaData: {
							metricName: metricNameWithFlavor,
							newComparisonDuration: newVal.value,
							statistic: stat.name,
							oldComparisonDuration: stat.options[stat.selectedOptionIndex].value,
						},
					});
				}}
			/>
		</Box>
	));

	[CountStatistic, PercentageStatistic, PercentageFirstStatistic, PercentagePreviousStatistic].forEach(
		(displayUnitStat) => {
			const displayUnit = displayUnits[displayUnitStat.key];

			if (!displayUnit.isDisabled) {
				statsLegendsElements.push(
					<Box key={displayUnitStat.key}>
						<ListItem
							hasRoundedCorners
							onClick={() => {
								toggleDisplayUnit(displayUnitStat.key);
								reportEvent({
									event: 'displayUnit-toggle-pressed',
									metaData: {
										metricName: metricNameWithFlavor,
										groupByList: breakdowns.values.map((o) => o.key),
										toggledDisplayUnit: displayUnitStat.name,
										'toggle-direction': displayUnit.value ? 'turn-off' : 'turn-on',
									},
								});
							}}
							prefixComponent={
								<Checkbox
									isChecked={displayUnit.value}
									label={displayUnitStat.name}
									checkboxColor={displayUnitStat.color}
									size="sm"
								/>
							}
							label=""
							size={'sm'}
						/>
					</Box>
				);
			}
		}
	);

	const getParametersOverrideURLSearchParams = useCallback(
		(component: MetricComponent): URLSearchParams | undefined => {
			if (!component.parametersOverride?.length) return;

			const parameters = { ...searchParams.parameters };
			const parametersWithOverrides = component.parametersOverride.reduce((out, override) => {
				return { ...out, [override.key]: override.value };
			}, parameters);
			return new URLSearchParams({ parameters: JSON.stringify(parametersWithOverrides) });
		},
		[searchParams.parameters]
	);

	const handleOnLinkClick = useCallback(
		(component: MetricComponent) => {
			const isComponent = legendState.title === componentsTitle;

			reportEvent({
				event: 'metric-drill-down-clicked',
				metaData: {
					metricName: metricNameWithFlavor,
					metricDisplayName: metricDisplayName,
					clickedDrillDown: component.label,
					isComponent: isComponent,
				},
			});

			if (isComponent) {
				navigate({
					path: `metric/${component.metricName}`,
					willIncludeAllSearchParams: true,
					isNewTab: true,
					additionalSearchParams: getParametersOverrideURLSearchParams(component),
				});
				return;
			}

			const newFilterParams = getFilterParams([{ key: breakdowns.values[0].key, value: component.label }]) || {};
			const currentFilterParams = searchParams['filterBy'] || {};
			const mergedParams = { ...searchParams, ['filterBy']: { ...currentFilterParams, ...newFilterParams } };

			const filteredParams = Object.entries(mergedParams).filter(([, v]) => v != null);
			const normalizedParams = Object.fromEntries(filteredParams.map(stringifySearchParams));
			const newSearchParams = new URLSearchParams(normalizedParams);
			newSearchParams.delete('groupBy');
			newSearchParams.delete('hiddenLegendNames');

			navigate({
				path: `metric/${metricNameWithFlavor}`,
				additionalSearchParams: newSearchParams,
				isNewTab: true,
			});
		},
		[
			breakdowns.values,
			getParametersOverrideURLSearchParams,
			legendState.title,
			metricDisplayName,
			metricNameWithFlavor,
			navigate,
			reportEvent,
			searchParams,
		]
	);

	const [currentDraggedComponent, setCurrentDraggedComponent] = useState<string | null>(null);
	const [isDragActive, setIsDragActive] = useState(false);
	const componentsLegendsElements = legendState.components.map((component) => {
		const componentKey = buildMetricComponentKey(component);
		return (
			<Box key={componentKey} style={{ border: 'none' }}>
				<LegendComponent
					isDragActive={isDragActive}
					isFluidCheckBox
					isDraggable
					component={component}
					onCheckChange={() => {
						if (currentDraggedComponent === componentKey) {
							setCurrentDraggedComponent(null);
							return;
						}
						toggleLegend(component.label ?? '');
						reportEvent({
							event: 'hiddenLegendNames-onTap',
							metaData: {
								metricName: metricNameWithFlavor,
								groupByList: breakdowns.values.map((o) => o.key),
								toggledLegend: component.label,
								'toggle-direction': component.isChecked ? 'turn-off' : 'turn-on',
							},
						});
					}}
					onLinkClick={() => {
						if (currentDraggedComponent === componentKey) {
							setCurrentDraggedComponent(null);
							return;
						}
						handleOnLinkClick(component);
					}}
				/>
			</Box>
		);
	});

	const gridLayoutLayout = useMemo(() => {
		return legendState.components.filter(isDefined).map((component, index) => ({
			i: buildMetricComponentKey(component),
			x: 0,
			y: index,
			w: 1,
			h: 1,
		}));
	}, [legendState.components]);

	const componentsLayout = (
		<ReactGridLayout
			draggableHandle={'.' + DRAG_HANDLE_CLASS}
			isResizable={false}
			className="layout"
			layout={gridLayoutLayout}
			cols={1}
			rowHeight={35}
			width={240}
			margin={[0, 0]}
			containerPadding={[0, 0]}
			isBounded={true}
			onDrag={(layout, child, newItem) => {
				setIsDragActive(true);
				if (child.x !== newItem.x || child.y !== newItem.y) {
					setCurrentDraggedComponent(child.i);
				}
			}}
			onDragStop={(layout) => {
				setIsDragActive(false);
				manualReorder(layout);
			}}
		>
			{componentsLegendsElements}
		</ReactGridLayout>
	);

	const realLegendPanel = (
		<>
			<LegendsSection
				title={'Data Format'}
				count={-1}
				elements={
					<DecimalPicker
						value={decimalDigits ?? 1}
						onChange={(value) => setPartialSearchParams({ decimalDigits: Number(value) })}
					/>
				}
				withBottomDivider={false}
			/>
			<LegendsSection
				title={'Main'}
				count={legendState.mainSeries.length}
				elements={mainLegendsElements} //
				withBottomDivider={!!legendState.components.length || !!statisticOperationState.length}
			/>
			<LegendsSection
				title={'Stats'}
				count={statsLegendsElements.length}
				elements={statsLegendsElements} //
				withBottomDivider={!!legendState.components.length}
			/>
			<LegendsSection
				withSelectAll
				title={legendState.title}
				count={legendState.components.length}
				withBottomDivider={false}
				reorderModal={<ReorderModal />}
				elements={legendState.components.length > 0 ? componentsLayout : null} //
			/>
		</>
	);

	const legendPanelClasses = [classes.legendPanel];
	if (isExpanded) legendPanelClasses.push(classes.expanded);
	if (isForceCollapse) legendPanelClasses.push(classes.forceCollapse);

	const legendPanelPlaceholderClasses = [classes.legendPanelPlaceholder];
	if (isExpanded) legendPanelPlaceholderClasses.push(classes.expanded);

	return (
		<Flex className={legendPanelPlaceholderClasses.join(' ')} as={'aside'} height="100%">
			<Flex boxShadow={shadows.borderLeft} overflowY="hidden" className={legendPanelClasses.join(' ')}>
				<Box
					flex={1}
					textAlign={'start'}
					overflowY={'auto'}
					overflowX="hidden"
					wordBreak="break-all"
					className={[classes.menuWhenExpanded, 'reorder-placeholder'].join(' ')}
				>
					<Box padding={'24px 8px 8px 8px'} display="block">
						{!isLoading ? realLegendPanel : <SkeletonLegendPanel />}
					</Box>
				</Box>
				<Box
					flex={1}
					textAlign={'start'}
					overflowY={'auto'}
					overflowX="hidden"
					wordBreak="break-all"
					className={classes.menuWhenCollapsed}
				>
					<Box padding={'16px'}>
						<IconButton
							icon={<Legend16 />}
							colorScheme="black"
							onClick={() => {
								setIsExpanded(true);
							}}
						/>
					</Box>
				</Box>
				<ExpandCollapseFooter
					isExpanded={isExpanded}
					setIsExpanded={(isExpanded) => {
						setIsExpanded(isExpanded);
						setIsForceCollapse(!isExpanded);
					}}
				/>
			</Flex>
		</Flex>
	);
}

type RenderSectionProps = {
	title: string;
	count: number;
	shouldShowClearAll?: boolean;
	elements: JSX.Element[] | JSX.Element | null;
	withBottomDivider?: boolean;
	reorderModal?: JSX.Element;
	withSelectAll?: boolean;
};

const LegendsSection = ({
	elements,
	title,
	count,
	withBottomDivider = true,
	reorderModal,
	withSelectAll,
}: RenderSectionProps) => {
	const { reportEvent } = useReportEvent();
	const [legendState, { setIsAllComponentsSelected }] = useDisplayedLegendState();

	const isChecked = legendState.components.length ? legendState.components.every((item) => item.isChecked) : false;
	const isIntermediate = legendState.components.some((item) => item.isChecked);

	const { metricNameWithFlavor, breakdowns } = useMetricDerivedState();

	if (!elements) {
		return null;
	}

	return (
		<>
			{!!count && (
				<Box>
					<Box paddingLeft={'16px'} height={'28px'}>
						<Title title={title} count={count} reorderModal={reorderModal} />
					</Box>
					{withSelectAll && legendState.components.length && (
						<Box style={{ border: 'none' }}>
							<LegendSelectAll
								isChecked={isChecked}
								onCheckChange={() => {
									setIsAllComponentsSelected();
									reportEvent({
										event: 'select-all-on-tap',
										metaData: {
											metricName: metricNameWithFlavor,
											groupByList: breakdowns.values.map((o) => o.key),
											'toggle-direction': isChecked ? 'turn-off' : 'turn-on',
										},
									});
								}}
								isIntermediate={!isChecked && isIntermediate}
							/>
						</Box>
					)}
					<Box overflow="hidden" transition="max-height 150ms">
						{elements}
					</Box>
					{withBottomDivider && title && <LegendDivider />}
				</Box>
			)}
		</>
	);
};

function Title({ title, count, reorderModal }: { title: string; count: number; reorderModal?: JSX.Element }) {
	return (
		<Flex
			textAlign="left"
			flexDirection="row"
			justifyContent="space-between"
			wordBreak={'break-all'}
			height={'28px'}
			alignItems="center"
			gap="4px"
			color="gray.600"
		>
			<Typography variant={'Disclaimer12SB'} noOfLines={1}>
				{title} {count > 0 && `(${count})`}
			</Typography>
			<Box paddingRight={'8px'}>{reorderModal}</Box>
		</Flex>
	);
}

function LegendDivider() {
	return (
		<Box w="100%">
			<Divider mb="16px" mt="16px" color="gray.300" mr="16px" ml="16px" direction="horizontal" />
		</Box>
	);
}
