import { BubbleSeries, ChartOptions, ChartSeries } from 'src/common/components/Chart/types';
import { NOT_AVAILABLE_VALUE_STRING } from 'src/common/utils/consts';
import { MetricDerivedState } from 'src/pages/MetricPage/utils/state.types';
import { BubbleStatistics, LineStatistics, MetricCalcResult } from 'src/types/metric';
import { DataLabelFormatConfig } from '../statisticOperations/types';
import {
	buildChartSeriesFromData,
	getFormattingHandlerByName,
	SeriesDataForChart,
	transposeMetricData,
} from '../utils';

export function calcStatistics(
	metricData: MetricCalcResult,
	prevChartOptions: ChartOptions
): Required<Pick<MetricDerivedState, 'chartOptions'>> {
	const transposedMetricData = transposeMetricData(metricData);
	const totalSeries = transposedMetricData.find((value) => value.type == 'TOTAL');
	if (!totalSeries) return { chartOptions: prevChartOptions };

	const lineStatistics = totalSeries.statistics
		.filter((statistic) => LineStatistics.includes(statistic.name as typeof LineStatistics[number]))
		.map((statistic) =>
			buildChartSeriesFromData(
				<SeriesDataForChart>{ ...statistic, yAxis: totalSeries.yAxis, type: 'STATISTIC' },
				'line',
				{
					dashStyle: 'Solid',
					strokeWidth: 1,
				}
			)
		);

	const bubbles: BubbleSeries[] = totalSeries.statistics
		.filter((statistic) => BubbleStatistics.includes(statistic.name as typeof BubbleStatistics[number]))
		.map((statistic) => ({
			type: statistic.name,
			name: statistic.name,
			dataPoints: statistic.results.map((result) => ({
				id: result.period.pretty,
				label: `${result.value ?? NOT_AVAILABLE_VALUE_STRING}`,
			})),
			custom: {
				seriesType: 'statistic',
			},
		}));

	return {
		chartOptions: {
			...prevChartOptions,
			series: [...prevChartOptions.series, ...lineStatistics],
			bubbles,
		},
	};
}

export function calcStatisticFormatting(
	chartOptions: ChartOptions,
	formatConfig: DataLabelFormatConfig
): Pick<MetricDerivedState, 'chartOptions'> {
	function injectFormatter<T extends BubbleSeries | ChartSeries>(seriesBeforeFormatting: T): T {
		const seriesDataPointYFormatter = getFormattingHandlerByName(
			seriesBeforeFormatting.name,
			chartOptions,
			formatConfig
		);

		if (!seriesDataPointYFormatter) {
			return seriesBeforeFormatting;
		}

		return {
			...seriesBeforeFormatting,
			custom: { ...seriesBeforeFormatting.custom, seriesDataPointYFormatter },
		};
	}

	const formattedBubbles = chartOptions.bubbles?.map(injectFormatter);
	const formattedSeries = chartOptions.series?.map(injectFormatter);

	return {
		chartOptions: {
			...chartOptions,
			bubbles: formattedBubbles,
			series: formattedSeries,
		},
	};
}
