import pipe from 'lodash/fp/pipe';
import get from 'lodash/fp/get';
import SightfulSelect from '@components/Select';
import classes from '@pages/MetricPage/components/PeriodPicker/PeriodPicker.module.scss';
import { Flex } from '@chakra-ui/react';
import classNames from 'classnames';
import isFunction from 'lodash/fp/isFunction';
import always from 'lodash/fp/always';
import { useEffect, useRef, useState } from 'react';

const t = function Translate(id: string, ...args: (string | undefined)[]): string {
	const dict: Record<string, string | ((...any: (string | undefined)[]) => string)> = {
		compare_to: 'Compare',
		previous_week: 'Previous Week',
		previous_month: 'Previous Month',
		previous_quarter: 'Previous Quarter',
		previous_year: 'Previous Year',
		custom: 'Custom',
		disabled_compare_period_tooltip: (compareToPeriod?: string, currentPeriod?: string) =>
			`${compareToPeriod} is not available when viewing ${currentPeriod}`,
	};
	const dictionaryItem = dict[id];
	return (isFunction(dictionaryItem) ? dictionaryItem : always(dictionaryItem))(...args);
};

export const COMPARE_TO_UNIT_SELECTOR_WEEK = 'week' as const;
export const COMPARE_TO_UNIT_SELECTOR_MONTH = 'month' as const;
export const COMPARE_TO_UNIT_SELECTOR_QUARTER = 'quarter' as const;
export const COMPARE_TO_UNIT_SELECTOR_YEAR = 'year' as const;
export const COMPARE_TO_UNIT_SELECTOR_CUSTOM = 'custom' as const;
type CompareToUnitSelectorValue =
	| typeof COMPARE_TO_UNIT_SELECTOR_WEEK
	| typeof COMPARE_TO_UNIT_SELECTOR_MONTH
	| typeof COMPARE_TO_UNIT_SELECTOR_QUARTER
	| typeof COMPARE_TO_UNIT_SELECTOR_YEAR
	| typeof COMPARE_TO_UNIT_SELECTOR_CUSTOM;

export default function CompareToSelector({
	unit,
	onUnitChange,
	disabledUnits = [],
	currentViewUnit,
}: {
	unit: undefined | CompareToUnitSelectorValue;
	onUnitChange: (unit: CompareToUnitSelectorValue | undefined) => void;
	disabledUnits: string[];
	currentViewUnit?: CompareToUnitSelectorValue;
}) {
	const elRef = useRef(null);
	const [isSelectorOpen, setIsSelectorOpen] = useState(false);

	useEffect(function () {
		const el = elRef.current;
		const clickHandler = () => setIsSelectorOpen(false);
		if (el) {
			document?.addEventListener('click', clickHandler);
		}

		return () => document?.removeEventListener('click', clickHandler);
	}, []);

	return (
		<>
			<Flex ref={elRef} className={classNames(classes.first, classes.last)} alignItems="center">
				<SightfulSelect
					onChange={pipe(get('value'), onUnitChange)}
					selectedOption={{
						value: unit,
						label: {
							none: t('compare_to'),
							week: t('previous_week'),
							month: t('previous_month'),
							quarter: t('previous_quarter'),
							year: t('previous_year'),
							custom: t('custom'),
						}[unit ?? 'none'],
					}}
					options={[
						{
							label: t('previous_week'),
							value: COMPARE_TO_UNIT_SELECTOR_WEEK,
							isDisabled: disabledUnits.includes(COMPARE_TO_UNIT_SELECTOR_WEEK),
							tooltip: disabledUnits.includes(COMPARE_TO_UNIT_SELECTOR_WEEK)
								? t('disabled_compare_period_tooltip', t('previous_week'), currentViewUnit)
								: null,
						},
						{
							label: t('previous_month'),
							value: COMPARE_TO_UNIT_SELECTOR_MONTH,
							isDisabled: disabledUnits.includes(COMPARE_TO_UNIT_SELECTOR_MONTH),
							tooltip: disabledUnits.includes(COMPARE_TO_UNIT_SELECTOR_MONTH)
								? t('disabled_compare_period_tooltip', t('previous_month'), currentViewUnit)
								: null,
						},
						{
							label: t('previous_quarter'),
							value: COMPARE_TO_UNIT_SELECTOR_QUARTER,
							isDisabled: disabledUnits.includes(COMPARE_TO_UNIT_SELECTOR_QUARTER),
							tooltip: disabledUnits.includes(COMPARE_TO_UNIT_SELECTOR_QUARTER)
								? t('disabled_compare_period_tooltip', t('previous_quarter'), currentViewUnit)
								: null,
						},
						{
							label: t('previous_year'),
							value: COMPARE_TO_UNIT_SELECTOR_YEAR,
							isDisabled: disabledUnits.includes(COMPARE_TO_UNIT_SELECTOR_YEAR),
							tooltip: disabledUnits.includes(COMPARE_TO_UNIT_SELECTOR_YEAR)
								? t('disabled_compare_period_tooltip', t('previous_year'), currentViewUnit)
								: null,
						},
					]}
					isGhost={true}
					size="small"
					onMenuOpen={() => setIsSelectorOpen(true)}
					onMenuClose={() => setIsSelectorOpen(false)}
					menuIsOpen={isSelectorOpen}
				></SightfulSelect>
			</Flex>
		</>
	);
}
