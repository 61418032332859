import { ChartOptions } from 'src/common/components/Chart/types';
import { MetricDerivedState } from 'src/pages/MetricPage/utils/state.types';
import { MetricCalcResult } from 'src/types/metric';
import { buildChartSeriesFromData, SeriesDataForChart, transposeMetricData } from '../utils';

export function calcTargetsChartOptions(
	metricData: MetricCalcResult,
	prevChartOptions: ChartOptions
): Required<Pick<MetricDerivedState, 'chartOptions' | 'availableTargets'>> {
	const transposedMetricData = transposeMetricData(metricData);
	const totalSeries = transposedMetricData.find((value) => value.type == 'TOTAL');
	if (!totalSeries) return { chartOptions: prevChartOptions, availableTargets: [] };
	const { yAxis, unit, op } = totalSeries;
	const targets = totalSeries.targets
		.map((target) =>
			buildChartSeriesFromData(<SeriesDataForChart>{ ...target, yAxis, unit, op, type: 'TOTAL' }, 'line', {
				dashStyle: 'Solid',
				strokeWidth: 1,
			})
		)
		.map((s) => ({
			...s,
			custom: {
				...s.custom,
				isTarget: true,
			},
		}));

	return {
		chartOptions: {
			...prevChartOptions,
			series: [...prevChartOptions.series, ...targets],
		},
		availableTargets: targets.map((t) => t.name),
	};
}
